import React, { Component } from 'react';

import Headers from '../../../components/Header';
import PedidosNav from '../../../components/Pedidos/PedidosNav';

class PedidosProduct extends Component {

    render() {

        return (
            <div className="container-fluid w-100 h-100 bg-light">
                <Headers />
                <PedidosNav />
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        COCO
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        CACA
                    </div>                    
                </div>
            </div>
        )

    }

}

export default PedidosProduct;