import React from 'react';
import { Link } from 'react-router-dom'
import "./menucategoria.css";

const MenuCategoria = () => (
    
        <div className="hidden-sm-down my-4 box menu-container">
            <ul id="menu">
                <li><div className="especial"><i className="material-icons icon">grade</i><div className="especial-nombre">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HABITUALES</div></div></li>
                <li><div className="especial"><i className="material-icons icon">local_offer</i><div className="especial-nombre">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OFERTAS</div></div></li>
                <li><div className="especial"><i className="material-icons icon">zoom_out_map</i><div className="especial-nombre">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LIQUIDACIÓN</div></div></li>
                <li><Link to="/pedidos" className="sub" tabIndex="1"><div>JAMONES Y PALETAS</div></Link>
                    <ul>
                        <li><Link to="/pedidos">ENTEROS</Link></li>
                        <li><Link to="/pedidos" title="DESHUESADOS CORTE Y OTRAS COSAS VARIAS">DESHUESADOS CORTE Y OTRAS COSAS VARIAS</Link></li>
                        <li><Link to="/pedidos">LIBRE SERVICIO</Link></li>
                    </ul>
                </li>
                <li><Link to="/pedidos" className="sub" tabIndex="1"><div>EMBUTIDOS CURADOS Y ASAR</div></Link>
                    <ul>
                        <li><Link to="/pedidos">CORTE Y HORECA</Link></li>
                        <li><Link to="/pedidos">LIBRE SERVICIO</Link></li>
                    </ul>
                </li>
                <li><Link to="/pedidos" className="sub"><div>ACEITES</div></Link>
                    <ul>
                        <li><Link to="/pedidos">HORECA</Link></li>
                        <li><Link to="/pedidos">LIBRE SERVICIO</Link></li>
                    </ul>                        
                </li>
                <li><Link to="/pedidos"><div>YOGURES NESTLE</div></Link></li>
                <li><Link to="/pedidos"><div>CAFES E INFUSIONES</div></Link></li>
            </ul>
        </div>
    
)


export default MenuCategoria;