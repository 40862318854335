import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form'

import { createCategoria, getCategoriaById, updateCategoriaById } from '../../../../redux/categorias/categoriasActions';

const CategoriasFicha = ({ user, categorias, toggleFicha, categoriaId, dispatchCreateCategoriaAction, dispatchGetCategoriaByIdAction, dispatchUpdateCategoriaAction}) => {

  const defaultValues = { id: '', activo: true, categoria: '', order_cat: 0, parent_cat: 0, socio_id: user.socio_id }
  const [ idDisabled, setIdDisabled ] = useState(false)
  /* Validación Formulario */
  const { register, handleSubmit, setValue, errors } = useForm({ defaultValues: defaultValues })
  const onSubmit = (data) => {
    const id = (categoriaId) ? categoriaId : data.id
    data  = { ...{ id: id, socio_id: user.socio_id }, ...data }
    if (categoriaId) {
      dispatchUpdateCategoriaAction(user.socio_id, categoriaId, data, () => {
          toast.success('Categoria actualizado con éxito!')
          toggleFicha()
      }, (message) => toast.error(`Error: ${message}`))
    } else {
      dispatchCreateCategoriaAction(data, () => {
          toast.success('Categoria creado con éxito!');
          toggleFicha();
      }, (message) => toast.error(`Error: ${message}`));
    }
  }

   useEffect(() => {
      if (categoriaId) {
        dispatchGetCategoriaByIdAction(user.socio_id, categoriaId, ({ activo, categoria, order_cat, parent_cat, socio_id }) => {
          setValue("id", categoriaId)
          setValue("activo", activo)
          setValue("categoria", categoria)
          setValue("order_cat", order_cat)
          setValue("parent_cat", parent_cat)
          setValue("socio_id", socio_id)
        });
        if (idDisabled === false) setIdDisabled(true)
      }
  }, [dispatchGetCategoriaByIdAction, categorias, categoriaId, user.socio_id, setValue, idDisabled, setIdDisabled]);

  const categoriasPadre = categorias 
    ? categorias.filter(categoria => { return categoria.parent_cat === '0' })
    : []

  return (
    <div className="container mt-4">
      <div className="row d-block">
        <div className="col-md-12">
          <h2>FICHA CATEGORÍA</h2>
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor="parent_cat">Categoria Padre *</label>
                <select 
                  ref={register( { required: { value: true, message: "Seleccione una categoría" } })}
                  type="text"
                  id="parent_cat"
                  name="parent_cat"
                  className="form-control">
                    <option key={-1} value="" disabled>Seleccionar opción</option>
                    <option key={0} value="0">INICIO</option>
                    {categoriasPadre.map(pad => <option key={pad.id} value={pad.id}>{pad.categoria}</option>)}
                </select>
                {errors.parent_cat && <p className='text-danger mt-1'>{errors.parent_cat.message}</p>} 
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="id">Cód. Categoria ERP *</label>
                <input
                  disabled={idDisabled}
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    },
                    maxLength: {
                      value: 15,
                      message: "15 caracteres máximo"
                    }
                  })}
                  type="text"
                  id="id"
                  placeholder="Cód. Categoría ERP"
                  name="id"
                  className="form-control"/>
                {errors.id && <p className='text-danger mt-1'>{errors.id.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="categoria">Nombre Categoria *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="categoria"
                  placeholder="Nombre Categoría"
                  name="categoria"
                  className="form-control"/>
                {errors.categoria && <p className='text-danger mt-1'>{errors.categoria.message}</p>}
              </div>
              <div className="form-group col-md-2">
                    <div className="form-check">
                        <div>Activado</div>
                        <label className="switch mt-2">
                            <input 
                                ref={ register({
                                  required: {
                                    value: true,
                                    message: "Campo requerido"
                                  }
                                })} 
                                type="checkbox"
                                id="activo"
                                name="activo"
                                />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>  
              <div className="form-group col-md-2">
                <label htmlFor="order_cat">Orden</label>
                <input 
                  ref={ register({
                    min: {
                      value: 0,
                      message: "Mínimo valor es 0"
                    },
                    max: {
                      value: 50,
                      message: "Máximo valor es 50"
                    },
                  })}
                  type="text"
                  id="order_cat"
                  placeholder="Orden"
                  name="order_cat"
                  className="form-control"/>
                {errors.order_cat && <p className='text-danger mt-1'>{errors.order_cat.message}</p>}
              </div>
            </div>
            <button type="submit" className="btn btn-success my-2">
              Guardar
            </button>
            <button className="btn btn-danger m-2" onClick={() => toggleFicha()}>Descartar</button>
          </form>
        </div>
      </div>
      <div onClick={() => toggleFicha()}
        style={{
          position: "absolute",
          right: "30px",
          top: "80px",
          cursor: "pointer",
          fontSize: "1.5em",
        }}
      >
        &#10005;
      </div>
    </div>
  );

}

const mapStateToProps = state => ({
  categorias: state.categorias,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchCreateCategoriaAction: (data, onSuccess, onError) =>
        dispatch(createCategoria(data, onSuccess, onError)),
    dispatchUpdateCategoriaAction: (socioId, categoriaId, data, onSuccess, onError) =>
        dispatch(updateCategoriaById(socioId, categoriaId, data, onSuccess, onError)),
    dispatchGetCategoriaByIdAction: (socioId, categoriaId, onSuccess) =>
        dispatch(getCategoriaById(socioId, categoriaId, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(CategoriasFicha);
