import React from 'react';
import './pedidosordenar.css';

const PedidosOrdenar = () => (
    <div className="pedidos-ordenar">
        <div className="pl-2" id="total-productos">
            Total Productos: 34
        </div>

        <div className="pr-2" id="select-ordenar">
            <select className="form-control form-control-sm">
                <option disabled defaultValue>Ordenar por...</option>
                <option>Nombre, A..Z</option>
                <option>Nombre, Z..A</option>
                <option>Precio, de más bajo a más alto</option>
                <option>Precio, de más alto a más bajo</option>            
            </select>
        </div>
    </div>
);

export default PedidosOrdenar;