import React from 'react';
import { Link } from 'react-router-dom'
import logo from '../../images/logo-hz-white.svg'

const Header = ({ userName, userPerfil, isLoggedIn, onLogout }) => {
    return (
        isLoggedIn && (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
        <Link className="navbar-brand pt-0" to="/dashboard">
            <img src={logo} height="16" alt="Ameta" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                    <Link className="nav-link" to="/dashboard">INICIO</Link>
                </li>
                {/*
                <li className="nav-item">
                    <Link className="nav-link" to="/pedidos">PEDIDOS</Link>
                </li>
                */} 
                { [3].includes(userPerfil) && 
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to="/" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    FUERZA DE VENTAS
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <Link className="dropdown-item" to="/dashboard">Venta Clientes</Link> 
                    <Link className="dropdown-item" to="/dashboard">Venta Producto</Link> 
                    <Link className="dropdown-item" to="/dashboard">Clientes Año Anterior</Link> 
                    </div>
                </li>
                }
                { [1, 2].includes(userPerfil) && 
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to="/" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    MAESTROS
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    { [1, 2].includes(userPerfil) && <Link className="dropdown-item" to="/usuarios-listado">Usuarios</Link>}    
                    { [1].includes(userPerfil) && <Link className="dropdown-item" to="/socios-listado">Socios</Link> }
                    { [2].includes(userPerfil) && <Link className="dropdown-item" to="/plataformas-listado">Surtidos</Link>}
                    { [2].includes(userPerfil) && <Link className="dropdown-item" to="/puntos-listado">Puntos de Venta</Link>}
                    { [2].includes(userPerfil) && <Link className="dropdown-item" to="/clientes-listado">Clientes</Link>}
                    { [2].includes(userPerfil) && <Link className="dropdown-item" to="/categorias-listado">Categorías</Link>}
                    { [2].includes(userPerfil) && <Link className="dropdown-item" to="/productos-listado">Productos</Link>}                    
                    { [2].includes(userPerfil) && <Link className="dropdown-item" to="/precios-listado">Precios</Link>}
                    </div>
                </li>
                }
            </ul>
            <ul className="navbar-nav">
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to="/" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {userName}
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <Link className="dropdown-item" to="/user-ficha">Mi Perfil</Link>
                    {/*<Link className="dropdown-item" to="/dashboard">Provisional 1</Link>*/}
                    {/*<Link className="dropdown-item" to="/dashboard">Provisional 2</Link>*/}
                    </div>
                </li>
            </ul>
            <button className="btn btn-sm btn-outline-secondary ml-4" type="button" onClick={onLogout}>Salir</button>
        </div>
        </nav>
        )
    )
}

export default Header;