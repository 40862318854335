import { API } from '../constants'
import { 
    SET_ALL_PRODUCTOS, 
    ADD_PRODUCTO, 
    REMOVE_PRODUCTO, 
    UPDATE_PRODUCTO 
} from './productosConstants'

export const fetchAllProductos = (socio_id) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/productos/lista/${socio_id}`,
        success: (response) => (setAllProductos(response))
    }
});

export const createProducto = (data, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'POST',
        url: '/productos',
        data,
        success: (producto) => (addProducto(producto)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getProductoById = (socioId, productoId, onSuccess) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/productos/${socioId}/${productoId}`,
        postProcessSuccess: onSuccess
    }
});

export const updateProductoById = (socioId, productoId, data, onSucess, onError) => ({
    type: API,
    payload: {
        method: 'PATCH',
        url: `/productos/`,
        data,
        success: (psocioId, roductoId, data) => (updateProducto(socioId, productoId, data)),
        postProcessSuccess: onSucess,
        postProcessError: onError
    }
});

export const deleteProductoById = (socioId, productoId, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'DELETE',
        url: `/productos/${socioId}/${productoId}`,
        success: () => (removeProducto(socioId, productoId)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

const addProducto = (producto) => ({
    type: ADD_PRODUCTO,
    payload: producto
})

const setAllProductos = (data) => ({
    type: SET_ALL_PRODUCTOS,
    payload: data
});

const updateProducto = (socioId, productoId, data) => ({
    type: UPDATE_PRODUCTO,
    payload: { socioId, productoId, data }
});

const removeProducto = (socioId, productoId) => ({
    type: REMOVE_PRODUCTO,
    payload: {socioId, productoId},
});