import { RESET_USER_INFO } from '../user/userConstants'
import { 
    SET_ALL_TARIFAS, 
    ADD_TARIFA, 
    REMOVE_TARIFA, 
    UPDATE_TARIFA 
} from './tarifasConstants'

export default function tarifaReducer(state=[], action) {
    switch(action.type) {
        case SET_ALL_TARIFAS:
            return action.payload;
        case ADD_TARIFA:
            return state.concat(action.payload);
        case REMOVE_TARIFA:
            return state.filter(item=>item.id !== action.payload);
        case UPDATE_TARIFA:
            return state.map(item => {
                if(item.id === action.payload.tarifaId)
                    return {...item, ...action.payload.data};
                else
                    return item;
            });
        case RESET_USER_INFO:
            return [];
        default:
            return state;
    }
}