import { RESET_USER_INFO } from '../user/userConstants'
import { 
    SET_ALL_CLIENTES, 
    ADD_CLIENTE, 
    REMOVE_CLIENTE, 
    UPDATE_CLIENTE 
} from './clientesConstants'

export default function clienteReducer(state=[], action) {
    switch(action.type) {
        case SET_ALL_CLIENTES:
            return action.payload;
        case ADD_CLIENTE:
            return state.concat(action.payload);
        case REMOVE_CLIENTE:
            return state.filter(item=>item.id !== action.payload);
        case UPDATE_CLIENTE:
            return state.map(item => {
                if(item.id === action.payload.clienteId)
                    return {...item, ...action.payload.data};
                else
                    return item;
            });
        case RESET_USER_INFO:
            return [];
        default:
            return state;
    }
}