import React from 'react';
import './pedidoscard.css';

const PedidosCard = (props) => {
    return (
        <div className="pedidos-card sombra">
            <img className="pedidos-card-img-top" src={require("../../../images/productos-m/" + props.id + ".jpg")} alt="" />
            <div className="pedidos-card-body">
                <p className="pedidos-card-nombre">{props.nombre}</p>
                <p className="pedidos-card-precio">{props.pvp} €/{props.um}</p>
            </div>
        </div>
    )
}

export default PedidosCard