import { API } from '../constants'
import { 
    SET_USER_INFO, 
    RESET_USER_INFO 
} from './userConstants'

export const userRegister = (data, onSuccess, onError) => {
    return {
        type: API,
        payload: {
            method: 'POST',
            url: '/users/register',
            data,
            success: (response) => (userSetInfo(response)),
            postProcessSuccess: onSuccess,
            postProcessError: onError
        }
    }
}

export const userLogin = (data, onSuccess, onError) => {
    
    return {
        type: API,
        payload: {
            method: 'POST',
            url: '/users/login',
            data,
            success: (response) => (userSetInfo(response)),
            postProcessSuccess: onSuccess,
            postProcessError: onError
        }
    }
}

export const userLogout = () => {
    localStorage.removeItem('USER_INFO')
    return { 
        type: RESET_USER_INFO 
    }
}

const userSetInfo = (data) => {
    const parsedToken = JSON.parse(atob(data.token.split('.')[1]));
    const userInfo = {
        id: parsedToken.result.id,
        email: data.email,
        nombre: data.nombre,
        apellidos: data.apellidos,
        socio_id: data.socio_id,
        cliente_id: data.cliente_id,
        token: data.token,
        perfil: data.perfil,
        isLoggedIn: true
    };
    localStorage.setItem('USER_INFO', JSON.stringify(userInfo));
    return { 
        type: SET_USER_INFO, 
        payload: userInfo 
    };
};
