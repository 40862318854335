import { RESET_USER_INFO } from '../user/userConstants'
import { 
    SET_ALL_SOCIOS, 
    ADD_SOCIO, 
    REMOVE_SOCIO, 
    UPDATE_SOCIO 
} from './sociosConstants'

export default function socioReducer(state=[], action) {
    switch(action.type) {
        case SET_ALL_SOCIOS:
            return action.payload;
        case ADD_SOCIO:
            return state.concat(action.payload);
        case REMOVE_SOCIO:
            return state.filter(item=>item.id !== action.payload);
        case UPDATE_SOCIO:
            return state.map(item => {
                if(item.id === action.payload.socioId)
                    return {...item, ...action.payload.data};
                else
                    return item;
            });
        case RESET_USER_INFO:
            return [];
        default:
            return state;
    }
}