import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './productosListado.css'
import { fetchAllProductos, deleteProductoById } from '../../../../redux/productos/productosActions';
import { fetchAllCategorias } from '../../../../redux/categorias/categoriasActions';

import ProductosFicha from '../ProductosFicha';

const ProductosListado = ({ user, productos, categorias, dispatchDeleteAction, dispatchFetchAllProductosAction, dispatchFetchAllCategoriasAction }) => {

    const [selectedProducto, setSelectedProducto] = useState('');
    const [showFicha, setShowFicha] = useState(false);
    const [showListado, setShowListado] = useState('');
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatchFetchAllCategoriasAction(user.socio_id)
        dispatchFetchAllProductosAction(user.socio_id)
    }, [user.socio_id, dispatchFetchAllCategoriasAction, dispatchFetchAllProductosAction])
/*
    const showConfirmationModal = (event, productoId) => {
        event.preventDefault();
        setSelectedProducto(productoId);
        window.$('#confirmationModal').modal('show');
    };
*/
    const productoAEditar = (productoId) => {
        setSelectedProducto(productoId);
    }

    const handleOnDelete = () => {
        dispatchDeleteAction(selectedProducto, () => {
            window.$('#confirmationModal').modal('hide');
            toast.success('Producto Borrado Correctamente!');
        }, (message) => {
            window.$('#confirmationModal').modal('hide');
            toast.error(`Error: ${message}`);
        });
    };

    const toggleFicha = () => {
        setShowFicha( showFicha ? false : true )
        setShowListado( !showFicha ? ' d-none' : '')
        showFicha && dispatchFetchAllProductosAction(user.socio_id)
    }

    var familias = []
    var subfamilias = []
    var productosListado = []
    var filteredProductos = []

    console.log("cat", categorias)

    if (categorias && categorias.length > 0) {

        categorias.filter(cat => {return cat.parent_cat === '0'}).map(cate => familias[cate.id] = {familia: cate.categoria }) 

        categorias.filter(cat => {return cat.parent_cat !== '0'}).map(cat => subfamilias[cat.id] = {familia: familias[cat.parent_cat].familia, subfamilia: cat.categoria, id: cat.id})

        productos.forEach(pr => { 
                //console.log(subfamilias)
                productosListado.push(
                {
                    familia: subfamilias[pr.subcat_id].familia,
                    subfamilia: subfamilias[pr.subcat_id].subfamilia,
                    nombre: pr.nombre,
                    id: pr.id, 
                    codigo_erp: pr.codigo_erp,
                    activo: pr.activo
                 }
        )})    
    
         

        var orderedProductos = productosListado.sort(function (a, b) {
            return (a.familia < b.familia ? -1 : 0) || (a.subfamilia < b.subfamilia ? -1 : 0)
        })

        filteredProductos = orderedProductos.filter( producto => {
            return producto.nombre.toLowerCase().includes( search.toLowerCase())
        })
    }

    return (
        <React.Fragment>
        { showFicha && <ProductosFicha productoId={selectedProducto} toggleFicha={toggleFicha} /> }
        <nav className={`navbar${showListado} navbar-white bg-white justify-content-between`} style={{marginTop:"-7px"}}>
            <span style={{fontSize:"1.7rem"}}>PRODUCTOS</span>
            <form className="form-inline input-group-sm">
                <div className="btn btn-secondary btn-sm mr-4" onClick={() => { productoAEditar(''); toggleFicha() }}>+ Nuevo Producto</div>
                <input className="form-control mr-sm-2" onChange={e => setSearch(e.target.value)} type="search" placeholder="Texto a buscar..." aria-label="Search" />
            </form>
        </nav>
        <div id="listado" className={`content${showListado}`}>
            <div id="tabla" className="tabla">
                <div className="listado-cabecera">
                    <div className="tabla-cabecera">
                        <div className="productos-familia">Familia</div>
                        <div className="productos-subfamilia">Subfamilia</div>
                        <div className="productos-nombre">Nombre</div>
                        <div className="productos-codigo-erp">Cód. ERP</div>
                        <div className="productos-activo">Activo</div>
                        {/*<div className="productos-borrar"></div>*/}
                    </div>
                </div>
                <div className="listado-cuerpo">
                    {   
                        filteredProductos.length > 0 ? 
                        filteredProductos.map((producto) => 
                            <div className="tabla-cuerpo" key={producto.id} onClick={() => { productoAEditar(producto.codigo_erp); toggleFicha() }}>
                                <div className="productos-familia text-break">{producto.familia}</div>
                                <div className="productos-subfamilia text-break">{producto.subfamilia}</div>
                                <div className="productos-nombre text-break">{producto.nombre}</div>
                                <div className="productos-codigo-erp text-break">{producto.codigo_erp}</div>
                                <div className="productos-activo text-break">{producto.activo}</div>
                                {/*
                                <div className="productos-borrar text-break">
                                    <a href="/" onClick={(e) => showConfirmationModal(e, producto.id)}>
                                        <i className="fas fa-trash-alt fa-2x text-danger"></i>
                                    </a>
                                </div>
                                */}
                            </div>
                        ) 
                        : <div className="text-center"></div>
                    }
                </div>
            </div>
        </div>
        <Modal handleOnDelete={handleOnDelete} />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    loading: state.loading,
    categorias: state.categorias,
    productos: state.productos,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchFetchAllCategoriasAction: (socio_id) => dispatch(fetchAllCategorias(socio_id)),
    dispatchFetchAllProductosAction: (socio_id) => dispatch(fetchAllProductos(socio_id)),
    dispatchDeleteAction: (productoId, onSuccess, onError) => dispatch(deleteProductoById(productoId, onSuccess, onError))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductosListado);

const Modal = ({ handleOnDelete }) => (
    <div className="modal" id="confirmationModal" tabIndex="-1" role="dialog">
        <div role="document" className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Confirmation</h5>
                </div>
                <div className="modal-body">
                    <p>Are you sure, you want to delete this note ?</p>
                </div>
                <div className="modal-footer">
                    <button type="button" data-dismiss="modal" className="btn btn-secondary">
                        No
                    </button>
                    <button type="button" data-dismiss="modal" onClick={handleOnDelete} className="btn btn-primary">
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
);
