import React from 'react';
import './pedidoscards.css';
import PedidoCard from '../../Pedidos/PedidosCard';
import Data from '../../../articulos.json';

const PedidosCards = () => {

    return( 
        <div className="pedidos-cards d-block">
        {   
            Data.map(post => {
                return(
                    <PedidoCard id={post.id} nombre={post.nombre} pvp={post.pvp} um={post.um} key={post.id} />
                )
            }) 
        }
        </div> 
    )
}

export default PedidosCards;
