import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { fetchAllCategorias } from '../../../../redux/categorias/categoriasActions'
import { createProducto, getProductoById, updateProductoById } from '../../../../redux/productos/productosActions';
import { BASE_URL } from '../../../../constants';

const ProductosFicha = ({ user, productos, categorias, toggleFicha, productoId, dispatchCreateProductoAction, dispatchGetProductoByIdAction, dispatchUpdateProductoAction, dispatchFetchAllCategoriasAction}) => {

  const defaultValues = { activo: true, codigo_erp:'', conservacion:'', descripcion:'', nombre:'', peso_pieza: '', peso_variable:'', referencia_ameta:'', subcat_id:'', unidad_medida:'', unidades_caja:'', fotos: 0 }

  /* Validación Formulario */
  const { register, handleSubmit, setValue, errors } = useForm({ defaultValues: defaultValues })

  const [ esVariable, setEsVariable ] = useState(true)

  const onSubmit = (data) => {
    data.fotos = (data.fotos) ? 1 : 0;
    data  = { ...{ socio_id: user.socio_id }, ...data }
    if (productoId) {
      dispatchUpdateProductoAction(user.socio_id, productoId, data, () => {
          toast.success('Producto actualizado con éxito!')
          toggleFicha()
      }, (message) => toast.error(`Error: ${message}`))
    } else {
      dispatchCreateProductoAction(data, () => {
          toast.success('Producto creado con éxito!');
          toggleFicha();
      }, (message) => toast.error(`Error: ${message}`));
    }
  }

  const modificaCodigo = (productoId) ? true : false;

  useEffect(() => {
      dispatchFetchAllCategoriasAction(user.socio_id)
      if (productoId) {
        dispatchGetProductoByIdAction(user.socio_id, productoId, ({ activo, codigo_erp, conservacion, descripcion, nombre, peso_pieza, peso_variable, referencia_ameta, subcat_id, unidad_medida, unidades_caja, fotos }) => {
          setValue("activo", activo)
          setValue("codigo_erp", codigo_erp )
          setValue("conservacion", conservacion)
          setValue("descripcion", descripcion)
          setValue("nombre",nombre )
          setValue("peso_pieza", peso_pieza)
          setValue("peso_variable", peso_variable)
          setValue("referencia_ameta", referencia_ameta)
          setValue("subcat_id", subcat_id)
          setValue("unidad_medida", unidad_medida)
          setValue("unidades_caja", unidades_caja)
          setValue("fotos", parseInt(fotos))
          setEsVariable((parseInt(peso_variable) === 1) ? false : true)
        });
      }
  }, [dispatchGetProductoByIdAction, productos, productoId, user.socio_id, setValue, dispatchFetchAllCategoriasAction]);

  var familias = []
  categorias.filter(cat => {return cat.parent_cat === '0'}).map(cate => familias[cate.id] = {familia: cate.categoria })

  var subfamilias = []
  categorias.filter(cat => {return cat.parent_cat !== '0'}).sort((a, b) => (a.parent_cat < b.parent_cat ? -1 : a.parent_cat > b.parent_cat ? 1 : 0))
      .map(cat => subfamilias.push({familia: familias[cat.parent_cat].familia, subfamilia: cat.categoria, id: cat.id}))

  //console.log("fam", familias, "sub", subfamilias)

  const onChangeVariable = (e) => {
    if (e.currentTarget.value === "1") {
      setEsVariable(false);
      console.log("esVariable, ", "1")
    } else {
      setEsVariable(true)
      console.log("esVariable, ", "0")
      setValue("peso_pieza", '')
    }
    console.log("current, ", e.currentTarget.value, " esvariables, ", esVariable)
  }

  const onChangeUpload = (e) => {
    var objetivo = e.target.files[0]
    if (objetivo.type !== "image/jpeg") {
      toast.error(`Error: El archivo no es una imagen JPG`)
    } else {
      if (objetivo.size > 50000) {
        toast.error(`Error: El archivo mayor que 50 kb. Optimice la imagen.`)
      } else {
          const data = new FormData()
          data.append('jpg', objetivo)
          data.append('socio_id', user.socio_id)
          data.append('producto_id', productoId)
          axios.post(BASE_URL + "/fotos/producto", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            }})
        .then(res => { // then print response status
            setValue("fotos", 1)
        })
      }
    }

  }


  return (
    <div className="container mt-4">
      <div className="row d-block">
        <div className="col-md-12">
          <h2>FICHA PRODUCTO</h2>
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label htmlFor="codigo_erp">Código ERP *</label>
                <input 
                  disabled={modificaCodigo}
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    },
                    maxLength: {
                      value: 20,
                      message: "Máx. 20 caracteres"
                    }
                  })}
                  type="text"
                  id="codigo_erp"
                  placeholder="Código ERP (20)"
                  name="codigo_erp"
                  className="form-control"/>
                {errors.codigo_erp && <p className='text-danger mt-1'>{errors.codigo_erp.message}</p>}
              </div>
              <div className="form-group col-md-5">
                <label htmlFor="nombre">Nombre Producto *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    },
                    maxLength: {
                      value: 50,
                      message: "Máx. 50 caracteres"
                    }
                  })}
                  type="text"
                  id="nombre"
                  placeholder="Nombre artículos (máx 50 caracteres)"
                  name="nombre"
                  className="form-control"/>
                {errors.nombre && <p className='text-danger mt-1'>{errors.nombre.message}</p>}
              </div>
              <div className="form-group col-md-5">
                <label htmlFor="subcat_id">Subcategoría *</label>
                <select 
                  ref={register( { required: { value: true, message: "Seleccione una Subcategoría" } })}
                  type="text"
                  id="subcat_id"
                  name="subcat_id"
                  className="form-control">
                    <option key={0} value="">Seleccionar opción</option>
                    {subfamilias.map(sub => <option key={sub.id} value={sub.id}>{sub.familia} {sub.subfamilia}</option>)}
                </select>
                {errors.subcat_id && <p className='text-danger mt-1'>{errors.subcat_id.message}</p>} 
              </div>
            </div>
            <div className="form-row"> 
              <div className="form-group col-md-2">
                <label htmlFor="unidad_medida">Udad. Medida *</label>
                <select 
                  ref={register( { required: { value: true, message: "Seleccione unidad de medida" } })}
                  type="text"
                  id="unidad_medida"
                  name="unidad_medida"
                  className="form-control">
                    <option value="">Seleccionar...</option>
                    <option value="KG">KG</option>
                    <option value="UD">UD</option>
                </select>
                {errors.unidad_medida && <p className='text-danger mt-1'>{errors.unidad_medida.message}</p>} 
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="peso_variable">¿Peso variable? *</label>
                <select 
                  onChange={onChangeVariable}
                  ref={register( { required: { value: true, message: "Indicar si es peso variable" } })}
                  type="text"
                  id="peso_variable"
                  name="peso_variable"
                  className="form-control">
                    <option value="">Seleccionar...</option>
                    <option value="1">SI</option>
                    <option value="0">NO</option>
                </select>
                {errors.peso_variable && <p className='text-danger mt-1'>{errors.peso_variable.message}</p>} 
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="peso_pieza">Peso Pieza *</label>
                <input noValidate
                  disabled={esVariable}
                  ref={ register({
                    pattern: {
                      value: /[0-9]/,
                      message: "Sólo números enteros"
                    },
                    min: {
                      value: 1,
                      message: "Mínimo 1 gramo"
                    },
                    max: {
                      value: 999999,
                      message: "Máximo 999999 gramos"
                    },
                    maxLength: {
                      value: 6,
                      message: "Máximo 999999 gramos"
                    }
                  })}
                  type="number"
                  id="peso_pieza"
                  placeholder="GRAMOS"
                  name="peso_pieza"
                  className="form-control"/>
                {errors.peso_pieza && <p className='text-danger mt-1'>{errors.peso_pieza.message}</p>}
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="unidades_caja">Udes/Pzas. Caja *</label>
                <input noValidate
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    },
                    pattern: {
                      value: /[0-9]/,
                      message: "Sólo números enteros"
                    },
                    min: {
                      value: 1,
                      message: "Mínimo 1 udad./pza."
                    },
                    max: {
                      value: 999,
                      message: "Máximo 999 udes./pzas."
                    },
                    maxLength: {
                      value: 3,
                      message: "Máximo 999 udes./pzas."
                    }
                  })}
                  type="number"
                  id="unidades_caja"
                  placeholder="Udes./Pzas. Caja"
                  name="unidades_caja"
                  className="form-control"/>
                {errors.unidades_caja && <p className='text-danger mt-1'>{errors.unidades_caja.message}</p>}
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="conservacion">Método Conservación *</label>
                <select 
                  ref={register( { required: { value: true, message: "Campo requerido" } })}
                  type="text"
                  id="conservacion"
                  name="conservacion"
                  className="form-control">
                    <option value="">Seleccionar...</option>
                    <option value="Ambiente">AMBIENTE</option>
                    <option value="Refrigerado">REFRIGERADO</option>
                    <option value="Congelado">CONGELADO</option>
                </select>
                {errors.conservacion && <p className='text-danger mt-1'>{errors.conservacion.message}</p>} 
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="descripcion">Metadescripción Producto</label>
                <input 
                  ref={ register }
                  type="text"
                  id="descripcion"
                  placeholder="Palabras adicionales al nombre del producto para incluirlas en las búsquedas."
                  name="descripcion"
                  className="form-control"/>
              </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="referencia_ameta">Código AMETA</label>
                    <input 
                      ref={ register({
                        maxLength: {
                          value: 20,
                          message: "Máximo 20 caracteres"
                        }
                      })}
                    type="text"
                    id="referencia_ameta"
                    placeholder="Ref. AMETA (20)"
                    name="referencia_ameta"
                    className="form-control"/>
                    {errors.referencia_ameta && <p className='text-danger mt-1'>{errors.referencia_ameta.message}</p>}
                </div>
                <div className="form-group col-md-2">
                    <div className="form-check">
                        <div>Activado</div>
                        <label className="switch mt-2">
                            <input 
                                ref={ register({
                                  required: {
                                    value: true,
                                    message: "Campo requerido"
                                  }
                                })} 
                                type="checkbox"
                                id="activo"
                                name="activo"
                                />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                
                <div className="form-group col-md-2">
                    <div className="form-check">
                        <div>Imagen</div>
                        <label className="switch mt-2">
                            <input 
                                disabled={productoId ? false : true}
                                ref={ register()} 
                                type="checkbox"
                                id="fotos"
                                name="fotos"
                                />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div> 
                { productoId && 
                <div className="form-group col-md-5">
                  <label htmlFor="referencia_ameta">Imagen Artículo 250x250 píxeles jpg</label>
                    <input type="file" name="file" onChange={onChangeUpload}/>
                </div> 
                }
            </div>
            <button type="submit" className="btn btn-success my-2">
              Guardar
            </button>
            <button className="btn btn-danger m-2" onClick={() => toggleFicha()}>Descartar</button>
          </form>
        </div>
      </div>
      <div onClick={() => toggleFicha()}
        style={{
          position: "absolute",
          right: "30px",
          top: "80px",
          cursor: "pointer",
          fontSize: "1.5em",
        }}
      >
        &#10005;
      </div>
    </div>
  );

}

const mapStateToProps = state => ({
  categorias: state.categorias,
  productos: state.productos,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchFetchAllCategoriasAction: (socio_id) => 
        dispatch(fetchAllCategorias(socio_id)),
    dispatchCreateProductoAction: (data, onSuccess, onError) =>
        dispatch(createProducto(data, onSuccess, onError)),
    dispatchUpdateProductoAction: (socio_id, productoId, data, onSuccess, onError) =>
        dispatch(updateProductoById(socio_id, productoId, data, onSuccess, onError)),
    dispatchGetProductoByIdAction: (socio_id, productoId, onSuccess) =>
        dispatch(getProductoById(socio_id, productoId, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductosFicha);
