import { API } from '../constants'
import { 
    SET_ALL_CATEGORIAS, 
    ADD_CATEGORIA, 
    REMOVE_CATEGORIA, 
    UPDATE_CATEGORIA 
} from './categoriasConstants'

export const fetchAllCategorias = (socio_id) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/categorias/lista/${socio_id}`,
        success: (response) => (setAllCategorias(response))
    }
});

export const createCategoria = (data, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'POST',
        url: '/categorias',
        data,
        success: (categoria) => (addCategoria(categoria)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getCategoriaById = (socioId, categoriaId, onSuccess) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/categorias/${socioId}/${categoriaId}`,
        postProcessSuccess: onSuccess
    }
});

export const updateCategoriaById = (socioId, categoriaId, data, onSucess, onError) => ({
    type: API,
    payload: {
        method: 'PATCH',
        url: `/categorias/`,
        data,
        success: (socioId, categoriaId, data) => (updateCategoria(socioId, categoriaId, data)),
        postProcessSuccess: onSucess,
        postProcessError: onError
    }
});

export const deleteCategoriaById = (socioId, categoriaId, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'DELETE',
        url: `/categorias/${socioId}/${categoriaId}`,
        success: () => (removeCategoria(socioId, categoriaId)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

const addCategoria = (categoria) => ({
    type: ADD_CATEGORIA,
    payload: categoria
})

const setAllCategorias = (data) => ({
    type: SET_ALL_CATEGORIAS,
    payload: data
});

const updateCategoria = (socioId, categoriaId, data) => ({
    type: UPDATE_CATEGORIA,
    payload: { socioId, categoriaId, data }
});

const removeCategoria = (socioId, categoriaId) => ({
    type: REMOVE_CATEGORIA,
    payload: {socioId, categoriaId},
});