import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { fetchAllSocios } from '../../redux/socios/sociosActions';
import { fetchAllClientes } from '../../redux/clientes/clientesActions'


const Dashboard = ({ user, dispatchFetchAllSociosAction, dispatchFetchAllClientesAction }) => {

  useEffect(() => {
    dispatchFetchAllSociosAction()
    dispatchFetchAllClientesAction(user.socio_id)
  }, [user.socio_id, user.cliente_id, dispatchFetchAllSociosAction, dispatchFetchAllClientesAction]);


  return (
    <div className="" >
      <h1 style={{margin: "20px"}}>Página Inicial (Dashboard) </h1>
    </div>
  )
}


const mapStateToProps = state => ({
  loading: state.loading,
  user: state.user,
  socios: state.socios,
  clientes: state.clientes
});

const mapDispatchToProps = dispatch => ({
  dispatchFetchAllSociosAction: () => dispatch(fetchAllSocios()),
  dispatchFetchAllClientesAction: (socio_id) => dispatch(fetchAllClientes(socio_id))
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);