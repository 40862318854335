import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form'
import md5 from 'md5';

import { createSocio, getSocioById, updateSocioById } from '../../../../redux/socios/sociosActions';

const SociosFicha = ({socios, toggleFicha, socioId, dispatchCreateSocioAction, dispatchGetSocioByIdAction, dispatchUpdateSocioAction}) => {
  const defaultValues = { nombre_comercial:'', nombre_fiscal:'', email_info:'', email_admin:'', email_comercial:'', contacto_persona:'', contacto_email:'', contacto_telefono:'', logo:'', direccion_1:'', direccion_2:'', poblacion:'', provincia:'', cod_post:'', activo: true, webservice_email: '', apikey: md5(new Date()), tpv_comercio:'', tpv_terminal:'', tpv_key:'' }

  const [webservicePass, setWebservicePass] = useState( false );

  /* Validación Formulario */
  const { register, handleSubmit, setValue, errors } = useForm({ defaultValues: defaultValues })
  const onSubmit = (data) => {
    const id = (socioId) ? socioId : ''
    data  = { ...{ id: id, logo: '' }, ...data }
    if (socioId) {
      dispatchUpdateSocioAction(socioId, data, () => {
          toast.success('Socio actualizado con éxito!')
          toggleFicha()
      }, (message) => toast.error(`Error: ${message}`))
    } else {
      dispatchCreateSocioAction(data, () => {
          toast.success('Socio creado con éxito!');
          toggleFicha();
      }, (message) => toast.error(`Error: ${message}`));
    }
  }

   useEffect(() => {
    if (socioId) {
      dispatchGetSocioByIdAction(socioId, ({ nombre_comercial, nombre_fiscal, email_info, email_admin, email_comercial, contacto_persona, contacto_email, contacto_telefono, logo, direccion_1, direccion_2, poblacion, provincia, cod_post, activo, webservice_email, apikey, tpv_comercio, tpv_terminal, tpv_key }) => {
          setValue("nombre_comercial", nombre_comercial)
          setValue("nombre_fiscal", nombre_fiscal)
          setValue("email_info", email_info)
          setValue("email_admin", email_admin)
          setValue("email_comercial", email_comercial)
          setValue("contacto_persona", contacto_persona)
          setValue("contacto_email", contacto_email)
          setValue("contacto_telefono", contacto_telefono)
          setValue("logo", logo)
          setValue("direccion_1", direccion_1)
          setValue("direccion_2", direccion_2)
          setValue("poblacion", poblacion)
          setValue("provincia", provincia)
          setValue("cod_post", cod_post)
          setValue("activo", activo)
          setValue("webservice_email", webservice_email)
          setValue("apikey", apikey)
          setValue("tpv_comercio", tpv_comercio)
          setValue("tpv_terminal", tpv_terminal)
          setValue("tpv_key", tpv_key)
      });
    }
  }, [dispatchGetSocioByIdAction, socios, socioId, setValue]);

  const handleOnUpdate = () => {
    fetch('https://api-dot-ameta-304206.ey.r.appspot.com/webservice/' + socioId)
    .then(response => response.json())
    .then(data => {
      if (data.affectedRows > 0) {
        toast.success('Contraseña Webservice Cambiada con Éxito!')
      } else {
        toast.error('No se ha podido cambiar la contraseña de webservice')
      }
      setWebservicePass(true)
    })
    .catch(err => {
      toast.error(`Error: ${err}`)
      setWebservicePass(true)
    });
  };

  const showConfirmationModal = (event) => {
    event.preventDefault();
    window.$('#confirmationModal').modal('show');
  };

  return (
    <React.Fragment>
    <div className="container mt-4">
      <div className="row d-block">
        <div className="col-md-12">
          <h2>FICHA SOCIO</h2>
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor="nombre_comercial">Nombre Comercial *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="nombre_comercial"
                  className="form-control"
                  placeholder="Nombre Comercial"
                  name="nombre_comercial"
                />
                {errors.nombre_comercial && <p className='text-danger mt-1'>{errors.nombre_comercial.message}</p>}

              </div>
              <div className="form-group col-md-3">
                <label htmlFor="nombre_fiscal">Nombre Fiscal *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="nombre_fiscal"
                  className="form-control"
                  placeholder="Nombre Fiscal *"
                  name="nombre_fiscal"
                 />
                 {errors.nombre_fiscal && <p className='text-danger mt-1'>{errors.nombre_fiscal.message}</p>}

              </div>
              <div className="form-group col-md-3">
                <label htmlFor="email_info">Email Info *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="email_info"
                  className="form-control"
                  placeholder="Email"
                  name="email_info"
                  />
                  {errors.email_info && <p className='text-danger mt-1'>{errors.email_info.message}</p>}

              </div>
              <div className="form-group col-md-3">
                <label htmlFor="email_admin">Email Admin *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="email_admin"
                  className="form-control"
                  placeholder="Email"
                  name="email_admin"
                  />
                  {errors.email_admin && <p className='text-danger mt-1'>{errors.email_admin.message}</p>}

              </div>
            </div>
            <div className="form-row"> 
              <div className="form-group col-md-3">
                <label htmlFor="email_comercial">Email Comercial *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="email_comercial"
                  className="form-control"
                  placeholder="Email"
                  name="email_comercial"
                  />
                  {errors.email_comercial && <p className='text-danger mt-1'>{errors.email_comercial.message}</p>}

              </div>
              <div className="form-group col-md-3">
                <label htmlFor="contacto_persona">Persona de Contacto *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="contacto_persona"
                  className="form-control"
                  placeholder="Nombre y Apellidos"
                  name="contacto_persona"
                  />
                  {errors.contacto_persona && <p className='text-danger mt-1'>{errors.contacto_persona.message}</p>}

              </div>
              <div className="form-group col-md-3">
                <label htmlFor="contacto_email">Email del Contacto *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="contacto_email"
                  className="form-control"
                  placeholder="Email"
                  name="contacto_email"
                  />
                  {errors.contacto_email && <p className='text-danger mt-1'>{errors.contacto_email.message}</p>}

              </div>
              <div className="form-group col-md-3">
                <label htmlFor="contacto_telefono">Teléfono del contacto *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="contacto_telefono"
                  className="form-control"
                  placeholder="Teléfono"
                  name="contacto_telefono"
                  />
                  {errors.contacto_telefono && <p className='text-danger mt-1'>{errors.contacto_telefono.message}</p>}

              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-5">
                <label htmlFor="direccion_1">Dirección Linea 1 *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="direccion_1"
                  className="form-control"
                  placeholder="Dirección Línea 1"
                  name="direccion_1"
                  />
                  {errors.direccion_1 && <p className='text-danger mt-1'>{errors.direccion_1.message}</p>}

              </div>
              <div className="form-group col-md-5">
                <label htmlFor="direccion_2">Dirección Linea 2</label>
                <input 
                  ref={ register({
                    maxLength: {
                      value: 50,
                      message: "Tamaño máximo 50 caracteres"
                    }
                  })}
                  type="text"
                  className="form-control"
                  id="direccion_2"
                  placeholder="Dirección Linea 2"
                  name="direccion_2"
                  />
                  {errors.direccion_2 && <p className='text-danger mt-1'>{errors.direccion_2.message}</p>}
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="cod_post">Cód.Postal *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                    type="text"
                    id="cod_post"
                    className="form-control"
                    placeholder="Código"
                    name="cod_post"
                    />
                    {errors.cod_post && <p className='text-danger mt-1'>{errors.cod_post.message}</p>}

                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-2">
                    <label htmlFor="poblacion">Población *</label>
                    <input 
                      ref={ register({
                        required: {
                          value: true,
                          message: "Campo requerido"
                        }
                      })}
                    type="text"
                    id="poblacion"
                    className="form-control"
                    placeholder="Población"
                    name="poblacion"
                    />
                    {errors.poblacion && <p className='text-danger mt-1'>{errors.poblacion.message}</p>}

                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="provincia">Provincia *</label>
                    <input 
                      ref={ register({
                        required: {
                          value: true,
                          message: "Campo requerido"
                        }
                      })}
                    type="text"
                    id="provincia"
                    className="form-control"
                    placeholder="Provincia"
                    name="provincia"
                    />
                    {errors.provincia && <p className='text-danger mt-1'>{errors.provincia.message}</p>}

                </div>
                <div className="form-group col-md-1">
                    <div className="form-check">
                        <div>Activado</div>
                        <label className="switch mt-2">
                            <input 
                                ref={register} 
                                type="checkbox"
                                id="activo"
                                name="activo"
                                />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>  
                {/* 
                <div className="form-group col-md-1">
                    <label htmlFor="logo">Logo *</label>
                    <input 

                      ref={ register()}
                      type="text"
                      id="logo"
                      className="form-control"
                      placeholder="Url Logo"
                      name="logo"
                    />
                    {errors.logo && <p className='text-danger mt-1'>{errors.logo.message}</p>}

                </div>
                */}
                <div className="form-group col-md-3">
                  <label htmlFor="webservice_email">Email WebService *</label>
                  <input 
                    ref={ register({
                      required: {
                        value: true,
                        message: "Campo requerido"
                      }
                    })}
                    type="text"
                    id="webservice_email"
                    className="form-control"
                    placeholder="Email Webservice"
                    name="webservice_email"
                    onChange={() => setWebservicePass(true)}
                    />
                    {errors.webservice_email && <p className='text-danger mt-1'>{errors.webservice_email.message}</p>}
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="apikey">Api Key</label>
                    <input 
                      disabled={true}
                      ref={ register({
                        required: {
                          value: true,
                          message: "Campo requerido"
                        }
                      })}
                    type="text"
                    id="apikey"
                    className="form-control"
                    name="apikey"
                    />
                    {errors.apikey && <p className='text-danger mt-1'>{errors.apikey.message}</p>}

                </div>               
            </div>
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="tpv_comercio">TPV Comercio</label>
                    <input 
                      ref={ register()}
                    type="text"
                    id="tpv_comercio"
                    className="form-control"
                    placeholder="TPV Comercio"
                    name="tpv_comercio"
                    />

                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="tpv_terminal">TPV Terminal</label>
                    <input 
                      ref={ register()}
                    type="text"
                    id="tpv_terminal"
                    className="form-control"
                    placeholder="TPV Terminal"
                    name="tpv_terminal"
                    />
                </div>    
                <div className="form-group col-md-4">
                    <label htmlFor="tpv_key">TPV Key</label>
                    <input 
                      ref={ register()}
                    type="text"
                    id="tpv_key"
                    className="form-control"
                    placeholder="TPV Key"
                    name="tpv_key"
                    />
                </div>
                <div className="form-group col-md-3">
                  <input disabled={webservicePass} type="button" value="Cambiar Contraseña Webservice" className="btn btn-secondary" style={{marginTop: '30px'}} onClick={(e) => showConfirmationModal(e)} />
                </div>                
            </div>
            <button type="submit" className="btn btn-success my-2">
              Guardar
            </button>
            <button className="btn btn-danger m-2" onClick={() => toggleFicha()}>Descartar</button>
          </form>
        </div>
      </div>
      <div onClick={() => toggleFicha()}
        style={{
          position: "absolute",
          right: "30px",
          top: "80px",
          cursor: "pointer",
          fontSize: "1.5em",
        }}
      >
        &#10005;
      </div>
    </div>
    <Modal handleOnUpdate={handleOnUpdate} />
    </React.Fragment>
  );

}

const Modal = ({ handleOnUpdate }) => (
  <div className="modal" id="confirmationModal" tabIndex="-1" role="dialog">
      <div role="document" className="modal-dialog">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title">Confirmación</h5>
              </div>
              <div className="modal-body">
                  <p>¿Desea actualizar la contraseña del Webservice de este socio?</p>
              </div>
              <div className="modal-footer">
                  <button type="button" data-dismiss="modal" className="btn btn-secondary">
                      No
                  </button>
                  <button type="button" data-dismiss="modal" onClick={handleOnUpdate} className="btn btn-primary">
                      Si
                  </button>
              </div>
          </div>
      </div>
  </div>
);

const mapStateToProps = state => ({
  socios: state.socios
});

const mapDispatchToProps = dispatch => ({
    dispatchCreateSocioAction: (data, onSuccess, onError) =>
        dispatch(createSocio(data, onSuccess, onError)),
    dispatchUpdateSocioAction: (socioId, data, onSuccess, onError) =>
        dispatch(updateSocioById(socioId, data, onSuccess, onError)),
    dispatchGetSocioByIdAction: (socioId, onSuccess) =>
        dispatch(getSocioById(socioId, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(SociosFicha);
