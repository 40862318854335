import { API } from '../constants'
import { 
    SET_ALL_TARIFAS, 
    ADD_TARIFA, 
    REMOVE_TARIFA, 
    UPDATE_TARIFA 
} from './tarifasConstants'

export const fetchAllTarifas = (socio_id) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/tarifas/lista/${socio_id}`,
        success: (response) => (setAllTarifas(response))
    }
});

export const createTarifa = (data, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'POST',
        url: '/tarifas',
        data,
        success: (tarifa) => (addTarifa(tarifa)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getTarifaById = (tarifaId, onSuccess) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/tarifas/${tarifaId}`,
        postProcessSuccess: onSuccess
    }
});

export const updateTarifaById = (tarifaId, data, onSucess, onError) => ({
    type: API,
    payload: {
        method: 'PATCH',
        url: `/tarifas/`,
        data,
        success: (tarifaId, data) => (updateTarifa(tarifaId, data)),
        postProcessSuccess: onSucess,
        postProcessError: onError
    }
});

export const deleteTarifaById = (tarifaId, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'DELETE',
        url: `/tarifas/${tarifaId}`,
        success: () => (removeTarifa(tarifaId)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

const addTarifa = (tarifa) => ({
    type: ADD_TARIFA,
    payload: tarifa
})

const setAllTarifas = (data) => ({
    type: SET_ALL_TARIFAS,
    payload: data
});

const updateTarifa = (tarifaId, data) => ({
    type: UPDATE_TARIFA,
    payload: { tarifaId, data }
});

const removeTarifa = (tarifaId) => ({
    type: REMOVE_TARIFA,
    payload: tarifaId,
});