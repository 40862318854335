import { 
    SET_USER_INFO, 
    RESET_USER_INFO 
} from './userConstants'

const defaultState = {
    id: null,
    token: null,
    nombre: null,
    apellido: null,
    perfil: null,
    admin: null,
    isLoggedIn: false
};

const userInfo = localStorage.getItem('USER_INFO');

const INITIAL_STATE = userInfo 
                ? JSON.parse(userInfo) 
                : defaultState;

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_INFO:
            return { 
                ...action.payload 
            }
        case RESET_USER_INFO:
            return {
                ...defaultState 
            }
        default:
            return state
    }
}

export default userReducer