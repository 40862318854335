import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form'

import { createCliente, getClienteById, updateClienteById } from '../../../../redux/clientes/clientesActions';

const ClientesFicha = ({ user, clientes, toggleFicha, clienteId, dispatchCreateClienteAction, dispatchGetClienteByIdAction, dispatchUpdateClienteAction}) => {

  const defaultValues = { id:'', nombre_comercial:'', nombre_fiscal:'', email_info:'', email_admin:'', email_comercial:'', contacto_persona:'', contacto_email:'', contacto_telefono:'', direccion_1:'', direccion_2:'', poblacion:'', provincia:'', cod_post:'', activo: true, prepago: false }

  /* Validación Formulario */
  const { register, handleSubmit, setValue, errors } = useForm({ defaultValues: defaultValues })
  const onSubmit = (data) => {
    const id = (clienteId) ? clienteId : ''
    data  = { ...{ id: id, socio_id: user.socio_id }, ...data }
    if (clienteId) {
      dispatchUpdateClienteAction(clienteId, data, () => {
          toast.success('Cliente actualizado con éxito!')
          toggleFicha()
      }, (message) => toast.error(`Error: ${message}`))
    } else {
      dispatchCreateClienteAction(data, () => {
          toast.success('Cliente creado con éxito!');
          toggleFicha();
      }, (message) => toast.error(`Error: ${message}`));
    }
  }

   useEffect(() => {
      if (clienteId) {
        dispatchGetClienteByIdAction(user.socio_id, clienteId, ({ id, nombre_comercial, nombre_fiscal, email_info, email_admin, email_comercial, contacto_persona, contacto_email, contacto_telefono, direccion_1, direccion_2, poblacion, provincia, cod_post, activo, prepago }) => {
          setValue("id", id)
          setValue("nombre_comercial", nombre_comercial)
          setValue("nombre_fiscal", nombre_fiscal)
          setValue("email_info", email_info)
          setValue("email_admin", email_admin)
          setValue("email_comercial", email_comercial)
          setValue("contacto_persona", contacto_persona)
          setValue("contacto_email", contacto_email)
          setValue("contacto_telefono", contacto_telefono)
          setValue("direccion_1", direccion_1)
          setValue("direccion_2", direccion_2)
          setValue("poblacion", poblacion)
          setValue("provincia", provincia)
          setValue("cod_post", cod_post)
          setValue("activo", activo)
          setValue("prepago", prepago)
        });
      }
  }, [dispatchGetClienteByIdAction, clientes, clienteId, user.socio_id, setValue]);

  return (
    <div className="container mt-4">
      <div className="row d-block">
        <div className="col-md-12">
          <h2>FICHA CLIENTE</h2>
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor="nombre_comercial">Nombre Comercial *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="nombre_comercial"
                  placeholder="Nombre Comercial"
                  name="nombre_comercial"
                  className="form-control"/>
                {errors.nombre_comercial && <p className='text-danger mt-1'>{errors.nombre_comercial.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="nombre_fiscal">Nombre Fiscal *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="nombre_fiscal"
                  placeholder="Nombre Fiscal *"
                  name="nombre_fiscal"
                  className="form-control"/>
                {errors.nombre_fiscal && <p className='text-danger mt-1'>{errors.nombre_fiscal.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="email_info">Email Info *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="email_info"
                  placeholder="Email"
                  name="email_info"
                  className="form-control"/>
                {errors.email_info && <p className='text-danger mt-1'>{errors.email_info.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="email_admin">Email Admin *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="email_admin"
                  placeholder="Email"
                  name="email_admin"
                  className="form-control"/>
                {errors.email_admin && <p className='text-danger mt-1'>{errors.email_admin.message}</p>}
              </div>
            </div>
            <div className="form-row"> 
              <div className="form-group col-md-3">
                <label htmlFor="email_comercial">Email Comercial *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="email_comercial"
                  placeholder="Email"
                  name="email_comercial"
                  className="form-control"/>
                {errors.email_comercial && <p className='text-danger mt-1'>{errors.email_comercial.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="contacto_persona">Persona de Contacto *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="contacto_persona"
                  placeholder="Nombre y Apellidos"
                  name="contacto_persona"
                  className="form-control"/>
                {errors.contacto_persona && <p className='text-danger mt-1'>{errors.contacto_persona.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="contacto_email">Email del Contacto *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="contacto_email"
                  placeholder="Email"
                  name="contacto_email"
                  className="form-control"/>
                {errors.contacto_email && <p className='text-danger mt-1'>{errors.contacto_email.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="contacto_telefono">Teléfono del contacto *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="contacto_telefono"
                  placeholder="Teléfono"
                  name="contacto_telefono"
                  className="form-control"/>
                {errors.contacto_telefono && <p className='text-danger mt-1'>{errors.contacto_telefono.message}</p>}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-5">
                <label htmlFor="direccion_1">Dirección Linea 1 *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="direccion_1"
                  placeholder="Dirección Línea 1"
                  name="direccion_1"
                  className="form-control"/>
                {errors.direccion_1 && <p className='text-danger mt-1'>{errors.direccion_1.message}</p>}
              </div>
              <div className="form-group col-md-5">
                <label htmlFor="direccion_2">Dirección Linea 2</label>
                <input 
                  ref={ register({
                    maxLength: {
                      value: 50,
                      message: "Máximo 50 caracteres"
                    }
                  })}
                  type="text"
                  className="form-control"
                  id="direccion_2"
                  placeholder="Dirección Linea 2"
                  name="direccion_2"
                />
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="cod_post">Cód.Postal *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                    type="text"
                    id="cod_post"
                    placeholder="Código"
                    name="cod_post"
                    className="form-control"/>
                    {errors.cod_post && <p className='text-danger mt-1'>{errors.cod_post.message}</p>}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="poblacion">Población *</label>
                    <input 
                      ref={ register({
                        required: {
                          value: true,
                          message: "Campo requerido"
                        }
                      })}
                    type="text"
                    id="poblacion"
                    placeholder="Población"
                    name="poblacion"
                    className="form-control"/>
                    {errors.poblacion && <p className='text-danger mt-1'>{errors.poblacion.message}</p>}
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="provincia">Provincia *</label>
                    <input 
                      ref={ register({
                        required: {
                          value: true,
                          message: "Campo requerido"
                        }
                      })}
                    type="text"
                    id="provincia"
                    placeholder="Provincia"
                    name="provincia"
                    className="form-control"/>
                    {errors.provincia && <p className='text-danger mt-1'>{errors.provincia.message}</p>}
                </div>
                <div className="form-group col-md-2">
                    <div className="form-check">
                        <div>Activado</div>
                        <label className="switch mt-2">
                            <input 
                                ref={ register(/*{
                                  required: {
                                    value: true,
                                    message: "Campo requerido"
                                  }
                                }*/)} 
                                type="checkbox"
                                id="activo"
                                name="activo"
                                />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <div className="form-group col-md-2">
                    <div className="form-check">
                        <div>Prepago</div>
                        <label className="switch mt-2">
                            <input 
                                ref={ register(/*{
                                  required: {
                                    value: true,
                                    message: "Campo requerido"
                                  }
                                }*/)} 
                                type="checkbox"
                                id="prepago"
                                name="prepago"
                                />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>    
                <div className="form-group col-md-2">
                    <label htmlFor="id">Código ERP *</label>
                    <input 
                      ref={ register({
                        required: {
                          value: true,
                          message: "Campo requerido"
                        }
                      })}
                    type="text"
                    id="id"
                    placeholder="Código en ERP del socio"
                    name="id"
                    className="form-control"/>
                    {errors.id && <p className='text-danger mt-1'>{errors.id.message}</p>}
                </div>
                
            </div>
            <button type="submit" className="btn btn-success my-2">
              Guardar
            </button>
            <button className="btn btn-danger m-2" onClick={() => toggleFicha()}>Descartar</button>
          </form>
        </div>
      </div>
      <div onClick={() => toggleFicha()}
        style={{
          position: "absolute",
          right: "30px",
          top: "80px",
          cursor: "pointer",
          fontSize: "1.5em",
        }}
      >
        &#10005;
      </div>
    </div>
  );

}

const mapStateToProps = state => ({
  clientes: state.clientes,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchCreateClienteAction: (data, onSuccess, onError) =>
        dispatch(createCliente(data, onSuccess, onError)),
    dispatchUpdateClienteAction: (clienteId, data, onSuccess, onError) =>
        dispatch(updateClienteById(clienteId, data, onSuccess, onError)),
    dispatchGetClienteByIdAction: (socioId, clienteId, onSuccess) =>
        dispatch(getClienteById(socioId, clienteId, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientesFicha);
