import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './preciosListado.css'
import { getPrecioByFiltro, deletePrecioById } from '../../../../redux/precios/preciosActions';
import { fetchAllProductos } from '../../../../redux/productos/productosActions';
import PreciosFicha from '../PreciosFicha';


const PreciosListado = ({ user, precios, productos, dispatchDeleteAction, dispatchGetPrecioByFiltro, dispatchFetchAllProductosAction }) => {

    const [selectedPrecio, setSelectedPrecio] = useState('');
    const [showFicha, setShowFicha] = useState(false);
    const [showListado, setShowListado] = useState('');
    const [search, setSearch] = useState('')
    const [precioTipo, setPrecioTipo] = useState('');
    const [precioCodigo, setPrecioCodigo] = useState('')
    const [codigoDisabled, setCodigoDisabled] = useState(true)
    const [valorPrecioCodigo, setValorPrecioCodigo] = useState('')

    useEffect(() => {
        if (productos.length === 0) dispatchFetchAllProductosAction(user.socio_id)
        if (precioTipo && precioCodigo) dispatchGetPrecioByFiltro(user.socio_id, precioTipo, precioCodigo);
    }, [precioTipo, precioCodigo, productos, user.socio_id, dispatchGetPrecioByFiltro, dispatchFetchAllProductosAction]);

    const showConfirmationModal = (event, prod, tip, cod) => {
        event.preventDefault();
        setSelectedPrecio({producto_id : prod, tipo: tip, codigo: cod});
        window.$('#confirmationModal').modal('show');
    };

    const precioAEditar = (prod, tip, cod) => {
        setSelectedPrecio({producto_id : prod, tipo: tip, codigo: cod});
    }

    const handleOnDelete = () => {
        dispatchDeleteAction(selectedPrecio, () => {
            window.$('#confirmationModal').modal('hide');
            toast.success('Precio Borrado Correctamente!');
        }, (message) => {
            window.$('#confirmationModal').modal('hide');
            toast.error(`Error: ${message}`);
        });
    };

    const toggleFicha = () => {
        setShowFicha( showFicha ? false : true )
        setShowListado( !showFicha ? ' d-none' : '')
        showFicha && dispatchGetPrecioByFiltro(user.socio_id, precioTipo, precioCodigo)
    }

    const filteredPrecios = precios.filter( precio => {
        return precio.producto_id.toLowerCase().includes( search.toLowerCase())
    })

    const onChangeTipo = (e) => {
        console.log("valor, ", e.target.value)
        setPrecioTipo(e.target.value)
        setCodigoDisabled(e.target.value === "" ? true : false)
        setValorPrecioCodigo('')
    }
    const onBlurCodigo = (e) => {
        setPrecioCodigo(e.target.value)
    }
    const onChangeCodigo = (e) => {
        setValorPrecioCodigo(e.target.value)
    }

    const filtrar = (codigo)  => {
        try {
            return productos.filter(p => { return p.codigo_erp.includes(codigo) } )[0].nombre
        } catch(error) {
            return "**** NO EXISTE ****";
        }
    }

    return (
        <React.Fragment>
        { showFicha && <PreciosFicha precioId={selectedPrecio} toggleFicha={toggleFicha} /> }
        <nav className={`navbar${showListado} navbar-white bg-white justify-content-between`} style={{marginTop:"-7px"}}>
            <span style={{fontSize:"1.7rem"}}>PRECIOS</span>
            <form className="form-inline input-group-sm">
                <select
                    type="text"
                    id="precioTipo"
                    name="precioTipo"
                    onChange={onChangeTipo}
                    className="form-control mr-2">
                        <option key={0} value="" defaultValue>Seleccionar Tipo Precio</option>
                        <option key={1} value="Tarifa">Tarifa</option>
                        <option key={2} value="Cliente">Cliente</option>
                </select>
                <input 
                  disabled={codigoDisabled}
                  type="text"
                  id="precioCodigo"
                  placeholder="Código Tarifa o Cliente"
                  name="precioCodigo"
                  onBlur={onBlurCodigo}
                  value={valorPrecioCodigo}
                  onChange={onChangeCodigo}
                  className="form-control mr-4"/>
                <div className="btn btn-secondary btn-sm mr-4" onClick={() => { precioAEditar(''); toggleFicha() }}>+ Nuevo Precio</div>
                <input className="form-control mr-sm-2" onChange={e => setSearch(e.target.value)} type="search" placeholder="Texto a buscar..." aria-label="Search" />
            </form>
        </nav>
        <div id="listado" className={`content${showListado}`}>
            <div id="tabla" className="tabla">
                <div className="listado-cabecera">
                    <div className="tabla-cabecera">
                        <div className="precios-id">Cód.</div>
                        <div className="precios-nombre">Producto</div>
                        <div className="precios-tipo">Tipo</div>
                        <div className="precios-clave">Clave</div>
                        <div className="precios-precio">Precio</div>
                        <div className="precios-descuento">Descuento</div>                        
                        <div className="precios-centimos">Céntimo</div>
                        {/*<div className="precios-borrar"></div>*/}
                    </div>
                </div>
                <div className="listado-cuerpo">
                    {   
                        filteredPrecios.length > 0 ? 
                        filteredPrecios.map((precio) => 
                            <div className="tabla-cuerpo" key={precio.producto_id + "_" + precio.tipo + "_" + precio.codigo} onClick={() => { precioAEditar(precio.producto_id, precio.tipo, precio.codigo); toggleFicha() }}>
                                <div className="precios-id">{precio.producto_id}</div>
                                <div className="precios-nombre">{filtrar(precio.producto_id)}</div>
                                <div className="precios-tipo">{precio.tipo}</div>
                                <div className="precios-clave">{precio.codigo}</div>
                                <div className="precios-precio">{precio.precio}</div>
                                <div className="precios-descuento">{precio.descuento}</div>                        
                                <div className="precios-centimos">{precio.centimos}</div>
                                {/*
                                <div className="precios-borrar text-break">
                                    <a href="/" onClick={(e) => showConfirmationModal(e, precio.producto_id)}>
                                        <i className="fas fa-trash-alt fa-2x text-danger"></i>
                                    </a>
                                </div>
                                */}
                            </div>
                        ) 
                        : <div className="text-center"></div>
                    }
                </div>
            </div>
        </div>
        <Modal handleOnDelete={handleOnDelete} />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    loading: state.loading,
    productos: state.productos,
    precios: state.precios,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchFetchAllProductosAction: (socio_id) => dispatch(fetchAllProductos(socio_id)),
    dispatchGetPrecioByFiltro: (socio_id, tipo, codigo) => dispatch(getPrecioByFiltro(socio_id, tipo, codigo)),
    dispatchDeleteAction: (precioId, onSuccess, onError) => dispatch(deletePrecioById(precioId, onSuccess, onError))
});

export default connect(mapStateToProps, mapDispatchToProps)(PreciosListado);

const Modal = ({ handleOnDelete }) => (
    <div className="modal" id="confirmationModal" tabIndex="-1" role="dialog">
        <div role="document" className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Confirmation</h5>
                </div>
                <div className="modal-body">
                    <p>Are you sure, you want to delete this note ?</p>
                </div>
                <div className="modal-footer">
                    <button type="button" data-dismiss="modal" className="btn btn-secondary">
                        No
                    </button>
                    <button type="button" data-dismiss="modal" onClick={handleOnDelete} className="btn btn-primary">
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
);


