import { combineReducers } from 'redux';

import user from './user/userReducer'
import loading from './loading/loadingReducer'
import socios from './socios/sociosReducer'
import usuarios from './usuarios/usuariosReducer'
import clientes from './clientes/clientesReducer'
import plataformas from './plataformas/plataformasReducer'
import puntos from './puntos/puntosReducer'
import categorias from './categorias/categoriasReducer'
import productos from './productos/productosReducer'
import tarifas from './tarifas/tarifasReducer'
import precios from './precios/preciosReducer'


const rootReducer = 
        combineReducers({ 
                            user, 
                            loading,
                            socios,
                            usuarios,
                            clientes,
                            plataformas,
                            puntos,
                            categorias,
                            productos,
                            tarifas,
                            precios
                        });

export default rootReducer;
