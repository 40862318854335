import { API } from '../constants'
import { 
    SET_ALL_USUARIOS, 
    ADD_USUARIO, 
    REMOVE_USUARIO, 
    UPDATE_USUARIO 
} from './usuariosConstants'

export const fetchAllUsuarios = (socio_id, cliente_id) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/users/lista/${socio_id}/${cliente_id}`,
        success: (response) => (setAllUsuarios(response))
    }
});

export const createUsuario = (data, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'POST',
        url: '/users',
        data,
        success: (usuario) => (addUsuario(usuario)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getUsuarioById = (usuarioId, onSuccess) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/users/${usuarioId}`,
        postProcessSuccess: onSuccess
    }
});

export const updateUsuarioById = (usuarioId, data, onSucess, onError) => ({
    type: API,
    payload: {
        method: 'PATCH',
        url: `/users/`,
        data,
        success: (usuarioId, data) => (updateUsuario(usuarioId, data)),
        postProcessSuccess: onSucess,
        postProcessError: onError
    }
});

export const deleteUsuarioById = (usuarioId, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'DELETE',
        url: `/users/${usuarioId}`,
        success: () => (removeUsuario(usuarioId)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

const addUsuario = (usuario) => ({
    type: ADD_USUARIO,
    payload: usuario
})

const setAllUsuarios = (data) => ({
    type: SET_ALL_USUARIOS,
    payload: data
});

const updateUsuario = (usuarioId, data) => ({
    type: UPDATE_USUARIO,
    payload: { usuarioId, data }
});

const removeUsuario = (usuarioId) => ({
    type: REMOVE_USUARIO,
    payload: usuarioId,
});