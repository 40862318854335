import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import logo from '../../images/logo-vt-white.svg'

import { userLogin } from '../../redux/user/userActions';

import './userLogin.css';

const UserLogin = ({dispatchLoginAction}) => {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleOnSubmit = (event) => {
      event.preventDefault();
      if (isFormInvalid()) updateErrorFlags();
      else {
          dispatchLoginAction(email, password,
              () => toast.success("¡Conexión correcta!"),
              (error) => toast.error(`Error: ${error}`)
          );
      }
  };

  const isFormInvalid = () => (!email || !password);

  const updateErrorFlags = () => {
      if (!email.trim() || !password.trim()) 
        toast.error("Email y Contraseña son necesarios")
  };

  return (
      <div className="bg-login">
        <div className="row">
          <div className="col-lg-4 col-md-3 col-sm-1 col-xs-12"></div>
          <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12">
              <div className="form-container p-5 mt-5 shadow-lg rounded">
                  <div className="row justify-content-center align-items-center mb-4">
                      <img src={logo} width="167" alt="Ameta" />
                  </div>
                  <form noValidate onSubmit={handleOnSubmit}>
                    <div className="form-group">
                        <label className="text-white">Email</label>
                        <input type="email" className="form-control" 
                              id="loginEmail" aria-describedby="emailHelp" placeholder="Email"
                              onChange = {(e) => {setEmail(e.target.value)}} />
                        <small id="emailHelp" className="form-text text-muted">Nunca compartiremos tu email con nadie.</small>
                    </div>
                    <div className="form-group">
                      <label className="text-white">Contraseña</label>
                      <input type="password" className="form-control" 
                              id="loginPassword" placeholder="Contraseña" 
                              onChange = {(e) => {setPassword(e.target.value)}} />
                    </div>
                    <button type="submit" className="btn btn-outline-secondary btn-block">Conectar</button>
                  </form>
              </div>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-1 col-xs-12"></div>
        </div>
      </div>
    )
  }


  const mapDispatchToProps = dispatch => ({
    dispatchLoginAction: (email, password, onSuccess, onError) =>
        dispatch(userLogin({ email, password }, onSuccess, onError))
});
export default connect(null, mapDispatchToProps)(UserLogin);