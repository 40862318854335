import { API } from '../constants'
import { 
    SET_ALL_CLIENTES, 
    ADD_CLIENTE, 
    REMOVE_CLIENTE, 
    UPDATE_CLIENTE 
} from './clientesConstants'

export const fetchAllClientes = (socio_id) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/clientes/lista/${socio_id}`,
        success: (response) => (setAllClientes(response))
    }
});

export const createCliente = (data, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'POST',
        url: '/clientes',
        data,
        success: (cliente) => (addCliente(cliente)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getClienteById = (socioId, clienteId, onSuccess) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/clientes/${socioId}/${clienteId}`,
        postProcessSuccess: onSuccess
    }
});

export const updateClienteById = (clienteId, data, onSucess, onError) => ({
    type: API,
    payload: {
        method: 'PATCH',
        url: `/clientes/`,
        data,
        success: (clienteId, data) => (updateCliente(clienteId, data)),
        postProcessSuccess: onSucess,
        postProcessError: onError
    }
});

export const deleteClienteById = (clienteId, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'DELETE',
        url: `/clientes/${clienteId}`,
        success: () => (removeCliente(clienteId)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

const addCliente = (cliente) => ({
    type: ADD_CLIENTE,
    payload: cliente
})

const setAllClientes = (data) => ({
    type: SET_ALL_CLIENTES,
    payload: data
});

const updateCliente = (clienteId, data) => ({
    type: UPDATE_CLIENTE,
    payload: { clienteId, data }
});

const removeCliente = (clienteId) => ({
    type: REMOVE_CLIENTE,
    payload: clienteId,
});