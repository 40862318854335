import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { userLogout } from './redux/user/userActions'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Slide } from 'react-toastify';
import './App.css';
import Header from './components/Header';
import Spinner from './components/Spinner'
import UserLogin from './pages/UserLogin'
import Dashboard from './pages/Dashboard';
import Pedidos from './pages/Pedidos/PedidosBoard';
import PedidosProduct from './pages/Pedidos/PedidosProduct';
import UserFicha from './components/UserFicha'
import SocioListado from './components/Maestros/Socios/SociosListado'
import UsuariosListado from './components/Maestros/Usuarios/UsuariosListado'
import ClientesListado from './components/Maestros/Clientes/ClientesListado'
import PlataformasListado from './components/Maestros/Plataformas/PlataformasListado'
import PuntosListado from './components/Maestros/Puntos/PuntosListado'
import CategoriasListado from './components/Maestros/Categorias/CategoriasListado'
import ProductosListado from './components/Maestros/Productos/ProductosListado'
import PreciosListado from './components/Maestros/Precios/PreciosListado'

const App = ({user, dispatchLogoutAction}) => {
    return (
        <React.Fragment>
          <ToastContainer position="top-right" autoClose={2000} hideProgressBar transition={Slide} />
          <Spinner />
          <div className="grid-container">
          <div className="header">
            <Header isLoggedIn={user.isLoggedIn} onLogout={dispatchLogoutAction} userName={`${user.nombre} ${user.apellidos}`} userPerfil={user.perfil} />
          </div>
          <div className="main">
          {!user.isLoggedIn ?
            (
              <Switch>
                <Route exact path="/user" component={UserLogin} />
                <Redirect to="/user" />
              </Switch>
            ) :
            (
              <Switch>
                <Route exact path="/user-ficha" component={UserFicha} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/pedidos" component={Pedidos} />
                <Route exact path="/pedidos-producto" component={PedidosProduct} />    
                <Route exact path="/socios-listado" component={SocioListado} />
                <Route exact path="/usuarios-listado" component={UsuariosListado} />
                <Route exact path="/clientes-listado" component={ClientesListado} />
                <Route exact path="/plataformas-listado" component={PlataformasListado} />
                <Route exact path="/puntos-listado" component={PuntosListado} />
                <Route exact path="/categorias-listado" component={CategoriasListado} />
                <Route exact path="/productos-listado" component={ProductosListado} />
                <Route exact path="/precios-listado" component={PreciosListado} />
                <Redirect to="/dashboard" />
              </Switch>
            )
          }
          </div>
          </div>

          </React.Fragment>
    );
}



const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLogoutAction: () => dispatch(userLogout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
