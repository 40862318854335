import { API } from '../constants'
import { 
    SET_ALL_PLATAFORMAS, 
    ADD_PLATAFORMA, 
    REMOVE_PLATAFORMA, 
    UPDATE_PLATAFORMA 
} from './plataformasConstants'

export const fetchAllPlataformas = (socio_id) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/plataformas/lista/${socio_id}`,
        success: (response) => (setAllPlataformas(response))
    }
});

export const createPlataforma = (data, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'POST',
        url: '/plataformas',
        data,
        success: (plataforma) => (addPlataforma(plataforma)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getPlataformaById = (socioId, plataformaId, onSuccess) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/plataformas/${socioId}/${plataformaId}`,
        postProcessSuccess: onSuccess
    }
});

export const updatePlataformaById = (socioId, plataformaId, data, onSucess, onError) => ({
    type: API,
    payload: {
        method: 'PATCH',
        url: `/plataformas/`,
        data,
        success: (socioId, plataformaId, data) => (updatePlataforma(socioId, plataformaId, data)),
        postProcessSuccess: onSucess,
        postProcessError: onError
    }
});

export const deletePlataformaById = (socioId, plataformaId, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'DELETE',
        url: `/plataformas/${socioId}/${plataformaId}`,
        success: () => (removePlataforma(socioId, plataformaId)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

const addPlataforma = (plataforma) => ({
    type: ADD_PLATAFORMA,
    payload: plataforma
})

const setAllPlataformas = (data) => ({
    type: SET_ALL_PLATAFORMAS,
    payload: data
});

const updatePlataforma = (socioId, plataformaId, data) => ({
    type: UPDATE_PLATAFORMA,
    payload: { socioId, plataformaId, data }
});

const removePlataforma = (socioId, plataformaId) => ({
    type: REMOVE_PLATAFORMA,
    payload: {socioId, plataformaId},
});