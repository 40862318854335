import { API } from '../constants'
import { 
    SET_ALL_SOCIOS, 
    ADD_SOCIO, 
    REMOVE_SOCIO, 
    UPDATE_SOCIO 
} from './sociosConstants'

export const fetchAllSocios = () => ({
    type: API,
    payload: {
        method: 'GET',
        url: '/socios',
        success: (response) => (setAllSocios(response))
    }
});

export const createSocio = (data, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'POST',
        url: '/socios',
        data,
        success: (socio) => (addSocio(socio)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getSocioById = (socioId, onSuccess) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/socios/${socioId}`,
        postProcessSuccess: onSuccess
    }
});

export const updateSocioById = (socioId, data, onSucess, onError) => ({
    type: API,
    payload: {
        method: 'PATCH',
        url: `/socios/`,
        data,
        success: (socioId, data) => (updateSocio(socioId, data)),
        postProcessSuccess: onSucess,
        postProcessError: onError
    }
});

export const deleteSocioById = (socioId, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'DELETE',
        url: `/socios/${socioId}`,
        success: () => (removeSocio(socioId)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

const addSocio = (socio) => ({
    type: ADD_SOCIO,
    payload: socio
})

const setAllSocios = (data) => ({
    type: SET_ALL_SOCIOS,
    payload: data
});

const updateSocio = (socioId, data) => ({
    type: UPDATE_SOCIO,
    payload: { socioId, data }
});

const removeSocio = (socioId) => ({
    type: REMOVE_SOCIO,
    payload: socioId,
});