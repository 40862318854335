import { RESET_USER_INFO } from '../user/userConstants'
import { 
    SET_ALL_PRECIOS, 
    SET_ALL_PRECIOS_FILTER,
    ADD_PRECIO, 
    REMOVE_PRECIO, 
    UPDATE_PRECIO 
} from './preciosConstants'

export default function precioReducer(state=[], action) {
    switch(action.type) {
        case SET_ALL_PRECIOS:
            return action.payload;
        case SET_ALL_PRECIOS_FILTER:
            return action.payload;
        case ADD_PRECIO:
            return state.concat(action.payload);
        case REMOVE_PRECIO:
            return state.filter(item=>item.id !== action.payload);
        case UPDATE_PRECIO:
            return state.map(item => {
                if(item.id === action.payload.precioId)
                    return {...item, ...action.payload.data};
                else
                    return item;
            });
        case RESET_USER_INFO:
            return [];
        default:
            return state;
    }
}