import React, { Component } from 'react';
import './pedidos.css';
import PedidosNav from '../../../components/Pedidos/PedidosNav';
import MenuCategoria from '../../../components/Menus/MenuCategoria';
import PedidosCategoriaNombre from '../../../components/Pedidos/PedidosCategoriaNombre';
import PedidosOrdenar from '../../../components/Pedidos/PedidosOrdenar';
import PedidosCards from '../../../components/Pedidos/PedidosCards';

class Pedidos extends Component {
    render() {
        return (
            <div className="container-fluid w-100 h-100 bg-light">
                <PedidosNav />
                <div className="row" style={{marginTop: "-15px"}}>
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                        <MenuCategoria />
                    </div>
                    <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-10">
                        <PedidosCategoriaNombre />
                        <PedidosOrdenar />
                        <PedidosCards />
                    </div>                    
                </div>
            </div>
        )
    }
}

export default Pedidos;
