import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form'

import { createPlataforma, getPlataformaById, updatePlataformaById } from '../../../../redux/plataformas/plataformasActions';

const PlataformasFicha = ({ user, plataformas, toggleFicha, plataformaId, dispatchCreatePlataformaAction, dispatchGetPlataformaByIdAction, dispatchUpdatePlataformaAction}) => {

  const defaultValues = { old_codigo_plataforma: '', codigo_plataforma:'', activo: true }

  /* Validación Formulario */
  const { register, handleSubmit, setValue, errors } = useForm({ defaultValues: defaultValues })
  const onSubmit = (data) => {
    data.activo = (data.activo) ? 1 : 0;
    data  = { ...{ socio_id: user.socio_id }, ...data }
    if (plataformaId) {
      dispatchUpdatePlataformaAction(user.socio_id, plataformaId, data, () => {
          toast.success('Plataforma actualizado con éxito!')
          toggleFicha()
      }, (message) => toast.error(`Error: ${message}`))
    } else {
      dispatchCreatePlataformaAction(data, () => {
          toast.success('Plataforma creado con éxito!');
          toggleFicha();
      }, (message) => toast.error(`Error: ${message}`));
    }
  }

   useEffect(() => {
      if (plataformaId) {
        dispatchGetPlataformaByIdAction(user.socio_id, plataformaId, ({ old_codigo_plataforma, codigo_plataforma, activo }) => {
          setValue("old_codigo_plataforma", old_codigo_plataforma)
          setValue("codigo_plataforma", codigo_plataforma)
          setValue("activo", activo)
        });
      }
  }, [dispatchGetPlataformaByIdAction, plataformas, plataformaId, user.socio_id, setValue]);

  return (
    <div className="container mt-4">
      <div className="row d-block">
        <div className="col-md-12">
          <h2>FICHA SURTIDO</h2>
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="nombre">Nombre Surtido*</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    },
                    maxLength: {
                      value: 20,
                      message: "Máximo 20 caracteres"
                    } 
                  })}
                  type="text"
                  id="codigo_plataforma"
                  placeholder="Nombre Surtido"
                  name="codigo_plataforma"
                  className="form-control"/>
                {errors.codigo_plataforma && <p className='text-danger mt-1'>{errors.codigo_plataforma.message}</p>}
              </div>
              <div className="form-group col-md-2">
                  <div className="form-check">
                      <div>Activado</div>
                      <label className="switch mt-2">
                          <input 
                              ref={ register()} 
                              type="checkbox"
                              id="activo"
                              name="activo"
                              />
                          <span className="slider round"></span>
                      </label>
                  </div>
              </div>    
            </div>
            <input 
                  disabled={false}
                  ref={ register() }
                  type="hidden"
                  id="old_codigo_plataforma"
                  placeholder="Nombre Surtido"
                  name="old_codigo_plataforma"
                  className="form-control"/>
            <button type="submit" className="btn btn-success my-2">
              Guardar
            </button>
            <button className="btn btn-danger m-2" onClick={() => toggleFicha()}>Descartar</button>
          </form>
        </div>
      </div>
      <div onClick={() => toggleFicha()}
        style={{
          position: "absolute",
          right: "30px",
          top: "80px",
          cursor: "pointer",
          fontSize: "1.5em",
        }}
      >
        &#10005;
      </div>
    </div>
  );

}

const mapStateToProps = state => ({
  plataformas: state.plataformas,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchCreatePlataformaAction: (data, onSuccess, onError) =>
        dispatch(createPlataforma(data, onSuccess, onError)),
    dispatchUpdatePlataformaAction: (socioId, plataformaId, data, onSuccess, onError) =>
        dispatch(updatePlataformaById(socioId, plataformaId, data, onSuccess, onError)),
    dispatchGetPlataformaByIdAction: (socioId, plataformaId, onSuccess) =>
        dispatch(getPlataformaById(socioId, plataformaId, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(PlataformasFicha);
