import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form'

import { createPunto, getPuntoById, updatePuntoById } from '../../../../redux/puntos/puntosActions';
import { fetchAllTarifas } from '../../../../redux/tarifas/tarifasActions';

const PuntosFicha = ({ user, puntos, clientes, usuarios, plataformas, tarifas, toggleFicha, puntoId, dispatchCreatePuntoAction, dispatchGetPuntoByIdAction, dispatchUpdatePuntoAction, dispatchFetchAllTarifasAction}) => {

  const defaultValues = { activo:true, cliente_id:'', comercial_id:'', cod_post:'', codigo_punto_venta:'', contacto_email:'', contacto_persona:'', contacto_telefono:'', direccion_1:'', direccion_2:'', nombre:'', observaciones_logistica:'', plataforma_id:'', poblacion:'', provincia:'', tarifa_id:'' }

  /* Validación Formulario */
  const { register, handleSubmit, setValue, errors } = useForm({ defaultValues: defaultValues })
  const onSubmit = (data) => {
    const id = (puntoId) ? puntoId : ''
    data  = { ...{ id: id, socio_id: user.socio_id }, ...data }
    if (puntoId) {
      dispatchUpdatePuntoAction(user.socio_id, puntoId, data, () => {
          toast.success('Punto actualizado con éxito!')
          toggleFicha()
      }, (message) => toast.error(`Error: ${message}`))
    } else {
      dispatchCreatePuntoAction(data, () => {
          toast.success('Punto creado con éxito!');
          toggleFicha();
      }, (message) => toast.error(`Error: ${message}`));
    }
  }

   useEffect(() => {
      dispatchFetchAllTarifasAction(user.socio_id);
      if (puntoId) {
        dispatchGetPuntoByIdAction(user.socio_id, puntoId, ({ activo, cliente_id, comercial_id, cod_post, codigo_punto_venta, contacto_email, contacto_persona, contacto_telefono, direccion_1, direccion_2, nombre, observaciones_logistica, plataforma_id, poblacion, provincia, tarifa_id }) => {
          setValue("activo", activo)
          setValue("cliente_id", cliente_id)
          setValue("comercial_id", comercial_id)
          setValue("cod_post", cod_post)
          setValue("codigo_punto_venta", codigo_punto_venta)
          setValue("contacto_email", contacto_email)
          setValue("contacto_persona", contacto_persona)
          setValue("contacto_telefono", contacto_telefono)
          setValue("direccion_1", direccion_1)
          setValue("direccion_2", direccion_2)
          setValue("nombre", nombre)
          setValue("observaciones_logistica", observaciones_logistica)
          setValue("plataforma_id", plataforma_id)
          setValue("poblacion", poblacion)
          setValue("provincia", provincia)
          setValue("tarifa_id", tarifa_id)
        });
      }
  }, [dispatchGetPuntoByIdAction, puntos, puntoId, user.socio_id, user.cliente_id, usuarios, clientes, setValue, dispatchFetchAllTarifasAction]);

  return (
    <div className="container mt-4">
      <div className="row d-block">
        <div className="col-md-12">
          <h2>FICHA PUNTO DE VENTA</h2>
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor="nombre">Nombre Punto de Venta *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="nombre"
                  placeholder="Nombre Punto de Venta"
                  name="nombre"
                  className="form-control"/>
                {errors.nombre && <p className='text-danger mt-1'>{errors.nombre.message}</p>}
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="codigo_punto_venta">Código ERP *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="codigo_punto_venta"
                  placeholder="Código Punto en ERP"
                  name="codigo_punto_venta"
                  className="form-control"/>
                {errors.codigo_punto_venta && <p className='text-danger mt-1'>{errors.codigo_punto_venta.message}</p>}
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="cliente_id">Cliente *</label>
                <select
                  ref={register( { required: { value: true, message: "Seleccione un cliente" } })}
                  type="text"
                  id="cliente_id"
                  name="cliente_id"
                  className="form-control">
                    <option key={-1} value="" disabled>Seleccionar opción</option>
                    {clientes.sort((a,b)=>(a.nombre_comercial < b.nombre_comercial ? -1 : a.nombre_comercial > b.nombre_comercial ? 1 : 0)).map((cli) => <option key={cli.id} value={cli.id}>{cli.nombre_comercial}</option>)}
                </select>
                {errors.cliente_id && <p className='text-danger mt-1'>{errors.cliente_id.message}</p>} 
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="tarifa_id">Tarifa *</label>
                <select
                  ref={register( { required: { value: true, message: "Seleccione un cliente" } })}
                  type="text"
                  id="tarifa_id"
                  name="tarifa_id"
                  className="form-control">
                    <option key={-1} value="" disabled>Seleccionar opción</option>
                    {tarifas.map((tar) => <option key={tar.codigo} value={tar.codigo}>{tar.codigo}</option>)}
                </select>
                {errors.tarifa_id && <p className='text-danger mt-1'>{errors.tarifa_id.message}</p>} 
              </div>
            </div>
            <div className="form-row"> 
              <div className="form-group col-md-2">
                <label htmlFor="comercial_id">Comercial *</label>
                <input 
                  ref={register( { 
                    required: { 
                      value: true, 
                      message: "Introduzca el código del comercial" 
                    },
                    maxLength: {
                    value: 15,
                    message: "Máximo 15 caracteres"
                    } 
                  })}
                  type="text"
                  id="comercial_id"
                  placeholder="Código del Comercial"
                  name="comercial_id"
                  className="form-control"/>
                {errors.comercial_id && <p className='text-danger mt-1'>{errors.comercial_id.message}</p>}
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="contacto_persona">Persona de Contacto *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="contacto_persona"
                  placeholder="Nombre y Apellidos"
                  name="contacto_persona"
                  className="form-control"/>
                {errors.contacto_persona && <p className='text-danger mt-1'>{errors.contacto_persona.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="contacto_email">Email del Contacto *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="contacto_email"
                  placeholder="Email"
                  name="contacto_email"
                  className="form-control"/>
                {errors.contacto_email && <p className='text-danger mt-1'>{errors.contacto_email.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="contacto_telefono">Teléfono del contacto *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="contacto_telefono"
                  placeholder="Teléfono"
                  name="contacto_telefono"
                  className="form-control"/>
                {errors.contacto_telefono && <p className='text-danger mt-1'>{errors.contacto_telefono.message}</p>}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-5">
                <label htmlFor="direccion_1">Dirección Linea 1 *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                  type="text"
                  id="direccion_1"
                  placeholder="Dirección Línea 1"
                  name="direccion_1"
                  className="form-control"/>
                {errors.direccion_1 && <p className='text-danger mt-1'>{errors.direccion_1.message}</p>}
              </div>
              <div className="form-group col-md-5">
                <label htmlFor="direccion_2">Dirección Linea 2</label>
                <input 
                  ref={ register({
                    maxLength: {
                      value: 50,
                      message: "Máximo 50 caracteres"
                    }
                  })}
                  type="text"
                  className="form-control"
                  id="direccion_2"
                  placeholder="Dirección Linea 2"
                  name="direccion_2"
                />
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="cod_post">Cód.Postal *</label>
                <input 
                  ref={ register({
                    required: {
                      value: true,
                      message: "Campo requerido"
                    }
                  })}
                    type="text"
                    id="cod_post"
                    placeholder="Código"
                    name="cod_post"
                    className="form-control"/>
                    {errors.cod_post && <p className='text-danger mt-1'>{errors.cod_post.message}</p>}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="poblacion">Población *</label>
                    <input 
                      ref={ register({
                        required: {
                          value: true,
                          message: "Campo requerido"
                        }
                      })}
                    type="text"
                    id="poblacion"
                    placeholder="Población"
                    name="poblacion"
                    className="form-control"/>
                    {errors.poblacion && <p className='text-danger mt-1'>{errors.poblacion.message}</p>}
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="provincia">Provincia *</label>
                    <input 
                      ref={ register({
                        required: {
                          value: true,
                          message: "Campo requerido"
                        }
                      })}
                    type="text"
                    id="provincia"
                    placeholder="Provincia"
                    name="provincia"
                    className="form-control"/>
                    {errors.provincia && <p className='text-danger mt-1'>{errors.provincia.message}</p>}
                </div>
                <div className="form-group col-md-2">
                    <div className="form-check">
                        <div>Activado</div>
                        <label className="switch mt-2">
                            <input 
                                ref={ register({
                                  required: {
                                    value: true,
                                    message: "Campo requerido"
                                  }
                                })} 
                                type="checkbox"
                                id="activo"
                                name="activo"
                                />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>    
                <div className="form-group col-md-4">
                  <label htmlFor="plataforma_id">Surtido *</label>
                  <select
                    ref={register( { required: { value: true, message: "Seleccione un Surtido" } })}
                    type="text"
                    id="plataforma_id"
                    name="plataforma_id"
                    className="form-control">
                      <option key={-1} value="" disabled>Seleccionar opción</option>
                      {plataformas.map((plat) => <option key={plat.codigo_plataforma} value={plat.codigo_plataforma}>{plat.codigo_plataforma}</option>)}
                  </select>
                  {errors.plataforma_id && <p className='text-danger mt-1'>{errors.plataforma_id.message}</p>} 
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label htmlFor="observaciones_logistica">Observaciones Logísticas</label>
                    <textarea 
                      ref={ register({
                        maxLength: {
                          value: 255,
                          message: "Máximo 255 caracteres"
                        }
                      })}
                    type="text"
                    id="observaciones_logistica"
                    placeholder="Observaciones logísticas para imprimir en el pedido"
                    name="observaciones_logistica"
                    className="form-control"/>
                    {errors.observaciones_logistica && <p className='text-danger mt-1'>{errors.observaciones_logistica.message}</p>}
                </div>
            </div>
            <button type="submit" className="btn btn-success my-2">
              Guardar
            </button>
            <button className="btn btn-danger m-2" onClick={() => toggleFicha()}>Descartar</button>
          </form>
        </div>
      </div>
      <div onClick={() => toggleFicha()}
        style={{
          position: "absolute",
          right: "30px",
          top: "80px",
          cursor: "pointer",
          fontSize: "1.5em",
        }}
      >
        &#10005;
      </div>
    </div>
  );

}

const mapStateToProps = state => ({
  puntos: state.puntos,
  user: state.user,
  tarifas: state.tarifas,
  usuarios: state.usuarios,
  clientes: state.clientes,
  plataformas: state.plataformas
});

const mapDispatchToProps = dispatch => ({
    dispatchFetchAllTarifasAction: (socio_id) => 
      dispatch(fetchAllTarifas(socio_id)),
    dispatchCreatePuntoAction: (data, onSuccess, onError) =>
        dispatch(createPunto(data, onSuccess, onError)),
    dispatchUpdatePuntoAction: (socioId, puntoId, data, onSuccess, onError) =>
        dispatch(updatePuntoById(socioId, puntoId, data, onSuccess, onError)),
    dispatchGetPuntoByIdAction: (socioId, puntoId, onSuccess) =>
        dispatch(getPuntoById(socioId, puntoId, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(PuntosFicha);