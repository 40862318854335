import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form'

import { createPrecio, getPrecioById, updatePrecioById } from '../../../../redux/precios/preciosActions';

const PreciosFicha = ({ user, precios, toggleFicha, precioId, dispatchCreatePrecioAction, dispatchGetPrecioByIdAction, dispatchUpdatePrecioAction}) => {

  const defaultValues = { old_codigo_precio: '', codigo_precio:'', activo: true }

  /* Validación Formulario */
  const { register, handleSubmit, setValue, errors } = useForm({ defaultValues: defaultValues })
  const onSubmit = (data) => {
    data.activo = (data.activo) ? 1 : 0;
    data  = { ...{ socio_id: user.socio_id }, ...data }
    if (precioId) {
      dispatchUpdatePrecioAction(user.socio_id, precioId, data, () => {
          toast.success('Precio actualizado con éxito!')
          toggleFicha()
      }, (message) => toast.error(`Error: ${message}`))
    } else {
      dispatchCreatePrecioAction(data, () => {
          toast.success('Precio creado con éxito!');
          toggleFicha();
      }, (message) => toast.error(`Error: ${message}`));
    }
  }

   useEffect(() => {
     console.log(precioId)
      if (precioId) {
        dispatchGetPrecioByIdAction(user.socio_id, precioId.producto_id, precioId.tipo, precioId.codigo, (data) => {
          setValue("producto_id", data.producto_id)
          setValue("tipo", data.tipo)
          setValue("codigo", data.codigo)
          setValue("precio", data.precio)
          setValue("descuento", data.descuento)
          setValue("centimos", data.centimos)
        });
      }
  }, [dispatchGetPrecioByIdAction, precios, precioId, user.socio_id, setValue]);

  return (
    <div className="container mt-4">
      <div className="row d-block">
        <div className="col-md-12">
          <h2>FICHA PRECIO</h2>
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label htmlFor="nombre">Código</label>
                <input 
                  disabled={true}
                  ref={ register({
                    maxLength: {
                      value: 20,
                      message: "Máximo 20 caracteres"
                    } 
                  })}
                  type="text"
                  id="producto_id"
                  placeholder="Nombre Surtido"
                  name="producto_id"
                  className="form-control"/>
                {errors.producto_id && <p className='text-danger mt-1'>{errors.producto_id.message}</p>}
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="nombre">Tipo Precio</label>
                <input 
                  disabled={true}
                  ref={ register()}
                  type="text"
                  id="tipo"
                  placeholder="Nombre Surtido"
                  name="tipo"
                  className="form-control"/>
                {errors.tipo && <p className='text-danger mt-1'>{errors.tipo.message}</p>}
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="nombre">Clave</label>
                <input 
                  disabled={true}
                  ref={ register()}
                  type="text"
                  id="codigo"
                  placeholder="Nombre Surtido"
                  name="codigo"
                  className="form-control"/>
                {errors.codigo && <p className='text-danger mt-1'>{errors.codigo.message}</p>}
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="nombre">Precio</label>
                <input 
                  ref={ register({
                    maxLength: {
                      value: 20,
                      message: "Máximo 20 caracteres"
                    } 
                  })}
                  type="number"
                  id="precio"
                  placeholder="Nombre Surtido"
                  name="precio"
                  className="form-control"/>
                {errors.precio && <p className='text-danger mt-1'>{errors.precio.message}</p>}
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="nombre">Descuento %</label>
                <input 
                  ref={ register({
                    maxLength: {
                      value: 20,
                      message: "Máximo 20 caracteres"
                    } 
                  })}
                  type="number"
                  id="descuento"
                  placeholder="Nombre Surtido"
                  name="descuento"
                  className="form-control"/>
                {errors.codigo_precio && <p className='text-danger mt-1'>{errors.codigo_precio.message}</p>}
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="nombre">Céntimos</label>
                <input 
                  ref={ register({
                    maxLength: {
                      value: 20,
                      message: "Máximo 20 caracteres"
                    } 
                  })}
                  type="number"
                  id="centimos"
                  placeholder="Nombre Surtido"
                  name="centimos"
                  className="form-control"/>
                {errors.centimos && <p className='text-danger mt-1'>{errors.centimos.message}</p>}
              </div>  
            </div>
            <button type="submit" className="btn btn-success my-2">
              Guardar
            </button>
            <button className="btn btn-danger m-2" onClick={() => toggleFicha()}>Descartar</button>
          </form>
        </div>
      </div>
      <div onClick={() => toggleFicha()}
        style={{
          position: "absolute",
          right: "30px",
          top: "80px",
          cursor: "pointer",
          fontSize: "1.5em",
        }}
      >
        &#10005;
      </div>
    </div>
  );

}

const mapStateToProps = state => ({
  precios: state.precios,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchCreatePrecioAction: (data, onSuccess, onError) =>
        dispatch(createPrecio(data, onSuccess, onError)),
    dispatchUpdatePrecioAction: (socioId, precioId, data, onSuccess, onError) =>
        dispatch(updatePrecioById(socioId, precioId, data, onSuccess, onError)),
    dispatchGetPrecioByIdAction: (socioId, producto_id, tipo, codigo, onSuccess) =>
        dispatch(getPrecioById(socioId, producto_id, tipo, codigo, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(PreciosFicha);
