import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './plataformasListado.css'
import { fetchAllPlataformas, deletePlataformaById } from '../../../../redux/plataformas/plataformasActions';

import PlataformasFicha from '../PlataformasFicha';

const PlataformasListado = ({ user, plataformas, dispatchDeleteAction, dispatchFetchAllPlataformasAction }) => {

    const [selectedPlataforma, setSelectedPlataforma] = useState('');
    const [showFicha, setShowFicha] = useState(false);
    const [showListado, setShowListado] = useState('');
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatchFetchAllPlataformasAction(user.socio_id);
    }, [user, dispatchFetchAllPlataformasAction]);
/*
    const showConfirmationModal = (event, plataformaId) => {
        event.preventDefault();
        setSelectedPlataforma(plataformaId);
        window.$('#confirmationModal').modal('show');
    };
*/
    const plataformaAEditar = (plataformaId) => {
        setSelectedPlataforma(plataformaId);
    }

    const handleOnDelete = () => {
        dispatchDeleteAction(selectedPlataforma, () => {
            window.$('#confirmationModal').modal('hide');
            toast.success('Plataforma Borrado Correctamente!');
        }, (message) => {
            window.$('#confirmationModal').modal('hide');
            toast.error(`Error: ${message}`);
        });
    };

    const toggleFicha = () => {
        setShowFicha( showFicha ? false : true )
        setShowListado( !showFicha ? ' d-none' : '')
        showFicha && dispatchFetchAllPlataformasAction(user.socio_id)
    }

    const filteredPlataformas = plataformas.filter( plataforma => {
        return plataforma.codigo_plataforma.toLowerCase().includes( search.toLowerCase())
    })

    return (
        <React.Fragment>
        { showFicha && <PlataformasFicha plataformaId={selectedPlataforma} toggleFicha={toggleFicha} /> }
        <nav className={`navbar${showListado} navbar-white bg-white justify-content-between`} style={{marginTop:"-7px"}}>
            <span style={{fontSize:"1.7rem"}}>SURTIDOS</span>
            <form className="form-inline input-group-sm">
                <div className="btn btn-secondary btn-sm mr-4" onClick={() => { plataformaAEditar(''); toggleFicha() }}>+ Nuevo Surtido</div>
                <input className="form-control mr-sm-2" onChange={e => setSearch(e.target.value)} type="search" placeholder="Texto a buscar..." aria-label="Search" />
            </form>
        </nav>
        <div id="listado" className={`content${showListado}`}>
            <div id="tabla" className="tabla">
                <div className="listado-cabecera">
                    <div className="tabla-cabecera">
                        <div className="plataformas-codigo">Código Surtido</div>
                        <div className="plataformas-activo">Activo</div>
                        {/*<div className="plataformas-borrar"></div>*/}
                    </div>
                </div>
                <div className="listado-cuerpo">
                    {   
                        filteredPlataformas.length > 0 ? 
                        filteredPlataformas.map((plataforma) => 
                            <div className="tabla-cuerpo" key={plataforma.codigo_plataforma} onClick={() => { plataformaAEditar(plataforma.codigo_plataforma); toggleFicha() }}>
                                <div className="plataformas-codigo text-break">{plataforma.codigo_plataforma}</div>
                                <div className="plataformas-activo text-break">{plataforma.activo}</div>
                                {/*
                                <div className="plataformas-borrar text-break">
                                    <a href="/" onClick={(e) => showConfirmationModal(e, plataforma.id)}>
                                        <i className="fas fa-trash-alt fa-2x text-danger"></i>
                                    </a>
                                </div>
                                */}
                            </div>
                        ) 
                        : <div className="text-center"></div>
                    }
                </div>
            </div>
        </div>
        <Modal handleOnDelete={handleOnDelete} />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    loading: state.loading,
    plataformas: state.plataformas,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchFetchAllPlataformasAction: (socio_id) => dispatch(fetchAllPlataformas(socio_id)),
    dispatchDeleteAction: (plataformaId, onSuccess, onError) => dispatch(deletePlataformaById(plataformaId, onSuccess, onError))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlataformasListado);

const Modal = ({ handleOnDelete }) => (
    <div className="modal" id="confirmationModal" tabIndex="-1" role="dialog">
        <div role="document" className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Confirmation</h5>
                </div>
                <div className="modal-body">
                    <p>Are you sure, you want to delete this note ?</p>
                </div>
                <div className="modal-footer">
                    <button type="button" data-dismiss="modal" className="btn btn-secondary">
                        No
                    </button>
                    <button type="button" data-dismiss="modal" onClick={handleOnDelete} className="btn btn-primary">
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
);
