import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
/*import { toast } from 'react-toastify';*/
import './usuariosListado.css'
import { fetchAllUsuarios, /* deleteUsuarioById */ } from '../../../../redux/usuarios/usuariosActions';
import { fetchAllSocios } from '../../../../redux/socios/sociosActions';
import { fetchAllClientes } from '../../../../redux/clientes/clientesActions'

import UsuariosFicha from '../UsuariosFicha';

const UsuariosListado = ({ user, loading, clientes, socios, usuarios, dispatchFetchAllUsuariosAction, dispatchFetchAllSociosAction, dispatchFetchAllClientesAction }) => {
    const [selectedUsuario, setSelectedusuario] = useState('')
    const [showFicha, setShowFicha] = useState(false)
    const [showListado, setShowListado] = useState('')
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatchFetchAllSociosAction()
        dispatchFetchAllClientesAction(user.socio_id)
        dispatchFetchAllUsuariosAction(user.socio_id, user.cliente_id)
    }, [user.socio_id, user.cliente_id, dispatchFetchAllUsuariosAction, dispatchFetchAllSociosAction, dispatchFetchAllClientesAction]);

    const usuarioAEditar = (usuarioId) => {
        console.log(usuarioId);
        setSelectedusuario(usuarioId);
    }

    const toggleFicha = () => {
        console.log(user)
        setShowFicha( !showFicha )
        setShowListado( !showFicha ? ' d-none' : '')
        showFicha && dispatchFetchAllUsuariosAction(user.socio_id, user.cliente_id)
    }

    const filteredUsuarios = usuarios.filter( usuario => {
        return (usuario.nombre.toLowerCase() + " " + usuario.apellidos.toLowerCase()/* + " " + usuario.socio.toLowerCase()*/).includes( search.toLowerCase())
    })

    var sociosNombre = []
    sociosNombre[0] = {nombre: "CENTRAL AMETA"}
    socios && socios.forEach(soc => {sociosNombre[soc.id] = {nombre: soc.nombre_comercial};})

    var clientesNombre = []
    clientesNombre[0] = {nombre: ""}
    clientes && clientes.forEach(cli => clientesNombre[cli.id] = {nombre: cli.nombre_comercial})
    var mostrar = true
/*
    if (user.socio_id === 0 && user.cliente_id === '0')
        if( sociosNombre.length > 1 && filteredUsuarios.length > 0) mostrar = true
    if (user.socio_id !== 0 && user.cliente_id === '0' ) 
        if( sociosNombre.length > 1 && filteredUsuarios.length > 0) mostrar = true
    if (user.socio_id !== 0 && user.cliente_id !== '0' ) 
        if (clientesNombre.length > 1 && sociosNombre.length > 1 && filteredUsuarios.length > 0) mostrar = true
*/

    return (
        <React.Fragment>
        { showFicha && <UsuariosFicha usuarioId={selectedUsuario} toggleFicha={toggleFicha} /> }
        <nav className={`navbar${showListado} navbar-white bg-white justify-content-between`} style={{marginTop:"-7px"}}>
            <span style={{fontSize:"1.7rem"}}>USUARIOS</span>
            <form className="form-inline input-group-sm">
                <div className="btn btn-secondary btn-sm mr-4" onClick={() => { usuarioAEditar(''); toggleFicha() }}>+ Nuevo Usuario</div>
                <input className="form-control mr-sm-2" onChange={e => setSearch(e.target.value)} type="search" placeholder="Buscar Nombre y Apellidos" aria-label="Search" />
            </form>
        </nav>

        <div id="listado" className={`content${showListado}`}>
            <div id="tabla" className="tabla">
                <div className="listado-cabecera">
                    <div className="tabla-cabecera">
                        <div className="usuarios-email">Email</div>
                        <div className="usuarios-nombre">Nombre</div>
                        <div className="usuarios-apellidos">Apellidos</div>
                        <div className="usuarios-socio">Socio</div>
                        <div className="usuarios-cliente">Cliente</div>
                        <div className="usuarios-comercial">Comercial</div>
                        <div className="usuarios-activo">Activo</div>
                        {/*<div className="usuarios-borrar"></div>*/}
                    </div>
                </div>
                <div className="listado-cuerpo">
                    {   
                        mostrar
                        ? filteredUsuarios.map((usuario) => 
                            <div className="tabla-cuerpo" key={usuario.id} onClick={() => { usuarioAEditar(usuario.id); toggleFicha() }}>
                                <div className="usuarios-email text-break">{usuario.email}</div>
                                <div className="usuarios-nombre text-break">{usuario.nombre}</div>
                                <div className="usuarios-apellidos text-break">{usuario.apellidos}</div>
                                <div className="usuarios-socio text-truncate">{sociosNombre[usuario.socio_id].nombre}</div>
                                <div className="usuarios-cliente text-truncate">{clientesNombre[usuario.cliente_id].nombre.substr(0,15)}</div>
                                <div className="usuarios-comercial">{usuario.comercial_id}</div>
                                <div className="usuarios-activo text-break">{usuario.activo}</div>
                            </div>
                        ) 
                        : <div className="text-center"></div>
                    }
                </div>
            </div>
        </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    loading: state.loading,
    user: state.user,
    socios: state.socios,
    clientes: state.clientes,
    usuarios: state.usuarios    
});

const mapDispatchToProps = dispatch => ({
    dispatchFetchAllUsuariosAction: (socio_id, cliente_id) => dispatch(fetchAllUsuarios(socio_id, cliente_id)),
    dispatchFetchAllSociosAction: () => dispatch(fetchAllSocios()),
    dispatchFetchAllClientesAction: (socio_id) => dispatch(fetchAllClientes(socio_id))
});
export default connect(mapStateToProps, mapDispatchToProps)(UsuariosListado);

