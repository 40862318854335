import { RESET_USER_INFO } from '../user/userConstants'
import { 
    SET_ALL_PRODUCTOS, 
    ADD_PRODUCTO, 
    REMOVE_PRODUCTO, 
    UPDATE_PRODUCTO 
} from './productosConstants'

export default function clienteReducer(state=[], action) {
    switch(action.type) {
        case SET_ALL_PRODUCTOS:
            return action.payload;
        case ADD_PRODUCTO:
            return state.concat(action.payload);
        case REMOVE_PRODUCTO:
            return state.filter(item=>item.id !== action.payload);
        case UPDATE_PRODUCTO:
            return state.map(item => {
                if(item.id === action.payload.clienteId)
                    return {...item, ...action.payload.data};
                else
                    return item;
            });
        case RESET_USER_INFO:
            return [];
        default:
            return state;
    }
}