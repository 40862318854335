import { API } from '../constants'
import { 
    SET_ALL_PRECIOS, 
    SET_ALL_PRECIOS_FILTER,
    ADD_PRECIO, 
    REMOVE_PRECIO, 
    UPDATE_PRECIO 
} from './preciosConstants'

export const fetchAllPrecios = (socio_id) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/precios/lista/${socio_id}`,
        success: (response) => (setAllPrecios(response))
    }
});

export const createPrecio = (data, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'POST',
        url: '/precios',
        data,
        success: (precio) => (addPrecio(precio)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getPrecioByFiltro = (socioId, tipo, codigo, onSuccess) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/precios/filtro/${socioId}/${tipo}/${codigo}`,
        success: (response) => (setAllPreciosFilter(response)),
        postProcessSuccess: onSuccess
    }
});

export const getPrecioById = (socioId, producto, tipo, codigo, onSuccess) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/precios/unico/${socioId}/${producto}/${tipo}/${codigo}`,
        postProcessSuccess: onSuccess
    }
});

export const updatePrecioById = (socioId, precioId, data, onSucess, onError) => ({
    type: API,
    payload: {
        method: 'PATCH',
        url: `/precios/`,
        data,
        success: (socioId, precioId, data) => (updatePrecio(socioId, precioId, data)),
        postProcessSuccess: onSucess,
        postProcessError: onError
    }
});

export const deletePrecioById = (socioId, precioId, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'DELETE',
        url: `/precios/${socioId}/${precioId}`,
        success: () => (removePrecio(socioId, precioId)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

const addPrecio = (precio) => ({
    type: ADD_PRECIO,
    payload: precio
})

const setAllPrecios = (data) => ({
    type: SET_ALL_PRECIOS,
    payload: data
});

const setAllPreciosFilter = (data) => ({
    type: SET_ALL_PRECIOS_FILTER,
    payload: data
});

const updatePrecio = (socioId, precioId, data) => ({
    type: UPDATE_PRECIO,
    payload: { socioId, precioId, data }
});

const removePrecio = (socioId, precioId) => ({
    type: REMOVE_PRECIO,
    payload: {socioId, precioId},
});