import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './sociosListado.css'
import { fetchAllSocios, deleteSocioById } from '../../../../redux/socios/sociosActions';

import SociosFicha from '../SociosFicha';

const SociosListado = ({ socios, dispatchDeleteAction, dispatchFetchAllSociosAction }) => {

    const [selectedSocio, setSelectedsocio] = useState('');
    const [showFicha, setShowFicha] = useState(false);
    const [showListado, setShowListado] = useState('');
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatchFetchAllSociosAction();
    }, [dispatchFetchAllSociosAction]);
/*
    const showConfirmationModal = (event, socioId) => {
        event.preventDefault();
        setSelectedsocio(socioId);
        window.$('#confirmationModal').modal('show');
    };
*/
    const socioAEditar = (socioId) => {
        console.log(socioId)
        setSelectedsocio(socioId);
    }

    const handleOnDelete = () => {
        dispatchDeleteAction(selectedSocio, () => {
            window.$('#confirmationModal').modal('hide');
            toast.success('socio Borrado Correctamente!');
        }, (message) => {
            window.$('#confirmationModal').modal('hide');
            toast.error(`Error: ${message}`);
        });
    };

    const toggleFicha = () => {
        setShowFicha( showFicha ? false : true )
        setShowListado( !showFicha ? ' d-none' : '')
        showFicha && dispatchFetchAllSociosAction()
    }

    const filteredSocios = socios.filter( socio => {
        return socio.nombre_comercial.toLowerCase().includes( search.toLowerCase())
    })

    return (
        <React.Fragment>
        { showFicha && <SociosFicha socioId={selectedSocio} toggleFicha={toggleFicha} /> }
        <nav className={`navbar${showListado} navbar-white bg-white justify-content-between`} style={{marginTop:"-7px"}}>
            <span style={{fontSize:"1.7rem"}}>SOCIOS</span>
            <form className="form-inline input-group-sm">
                <div className="btn btn-secondary btn-sm mr-4" onClick={() => { socioAEditar(''); toggleFicha() }}>+ Nuevo Socio</div>
                <input className="form-control mr-sm-2" onChange={e => setSearch(e.target.value)} type="search" placeholder="Texto a buscar..." aria-label="Search" />
            </form>
        </nav>
        <div id="listado" className={`content${showListado}`}>
            <div id="tabla" className="tabla">
                <div className="listado-cabecera">
                    <div className="tabla-cabecera">
                        <div className="socios-nombre">Nombre</div>
                        <div className="socios-contacto">Contacto</div>
                        <div className="socios-telefono">Teléfono</div>
                        <div className="socios-provincia">Provincia</div>
                        {/*<div className="socios-borrar"></div>*/}
                    </div>
                </div>
                <div className="listado-cuerpo">
                    {   
                        filteredSocios ? 
                        filteredSocios.map((socio) => 
                            <div className="tabla-cuerpo" key={socio.id} onClick={() => { socioAEditar(socio.id); toggleFicha() }}>
                                <div className="socios-nombre text-break">{socio.nombre_comercial}</div>
                                <div className="socios-contacto text-break">{socio.contacto_persona}</div>
                                <div className="socios-telefono text-break">{socio.contacto_telefono}</div>
                                <div className="socios-provincia text-break">{socio.provincia}</div>
                                {/*
                                <div className="socios-borrar text-break">
                                    <a href="/" onClick={(e) => showConfirmationModal(e, socio.id)}>
                                        <i className="fas fa-trash-alt fa-2x text-danger"></i>
                                    </a>
                                </div>
                                */}
                            </div>
                        ) 
                        : <div className="text-center">
                            <h2><i className="far fa-folder-open fa-3x"></i></h2>
                            <h1 className="text-center">No hay ningún SOCIO creado</h1>
                        </div>
                    }
                </div>
            </div>
        </div>
        <Modal handleOnDelete={handleOnDelete} />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    loading: state.loading,
    socios: state.socios
});

const mapDispatchToProps = dispatch => ({
    dispatchFetchAllSociosAction: () => dispatch(fetchAllSocios()),
    dispatchDeleteAction: (socioId, onSuccess, onError) => dispatch(deleteSocioById(socioId, onSuccess, onError))
});

export default connect(mapStateToProps, mapDispatchToProps)(SociosListado);

const Modal = ({ handleOnDelete }) => (
    <div className="modal" id="confirmationModal" tabIndex="-1" role="dialog">
        <div role="document" className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Confirmation</h5>
                </div>
                <div className="modal-body">
                    <p>Are you sure, you want to delete this note ?</p>
                </div>
                <div className="modal-footer">
                    <button type="button" data-dismiss="modal" className="btn btn-secondary">
                        No
                    </button>
                    <button type="button" data-dismiss="modal" onClick={handleOnDelete} className="btn btn-primary">
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
);
