import axios from 'axios';
import { API, TOGGLE_LOADER } from './constants';
import { BASE_URL } from '../constants';
import { userLogout } from './user/userActions'

const apiMiddleware = ({ dispatch, getState }) => next => action => {
    
    if (action.type !== API) return next(action);
    
    dispatch({ type: TOGGLE_LOADER });
    
    const AUTH_TOKEN = getState().user.token;
    if (AUTH_TOKEN)
        axios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
    
    const { url, method, success, data, postProcessSuccess, postProcessError } = action.payload;
    console.log("url", url, "dataIn", data)
    axios({
        method,
        url: BASE_URL + url,
        data: data ? data : null
    }).then((response) => {
        dispatch({ type: TOGGLE_LOADER });
        if (response.data.error) {
            console.log("NO ha ido bien", url, response.data.error)
            if (response.data.success === 0) {
                dispatch(userLogout());
            }
            if (postProcessError) postProcessError(response.data.error);
        } else {
            console.log("Ha ido bien", url, "dataOut", response.data)
            if (success) dispatch(success(response.data));
            if (postProcessSuccess) postProcessSuccess(response.data);
        }
    }).catch(err => {
        dispatch({ type: TOGGLE_LOADER });
        if (!err.response) 
            console.warn("errorcito", err);
        else {
            if (err.response && err.response.status === 403)
                dispatch(userLogout());
            if (err.response.data.message) {
                if (postProcessError) postProcessError(err.response.data.message);
            }
        }
    })
}

export default apiMiddleware
