import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './clientesListado.css'
import { fetchAllClientes, deleteClienteById } from '../../../../redux/clientes/clientesActions';

import ClientesFicha from '../ClientesFicha';

const ClientesListado = ({ user, clientes, dispatchDeleteAction, dispatchFetchAllClientesAction }) => {

    const [selectedCliente, setSelectedCliente] = useState('');
    const [showFicha, setShowFicha] = useState(false);
    const [showListado, setShowListado] = useState('');
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatchFetchAllClientesAction(user.socio_id);
    }, [user, dispatchFetchAllClientesAction]);
/*
    const showConfirmationModal = (event, clienteId) => {
        event.preventDefault();
        setSelectedCliente(clienteId);
        window.$('#confirmationModal').modal('show');
    };
*/
    const clienteAEditar = (clienteId) => {
        setSelectedCliente(clienteId);
    }

    const handleOnDelete = () => {
        dispatchDeleteAction(selectedCliente, () => {
            window.$('#confirmationModal').modal('hide');
            toast.success('Cliente Borrado Correctamente!');
        }, (message) => {
            window.$('#confirmationModal').modal('hide');
            toast.error(`Error: ${message}`);
        });
    };

    const toggleFicha = () => {
        setShowFicha( showFicha ? false : true )
        setShowListado( !showFicha ? ' d-none' : '')
        showFicha && dispatchFetchAllClientesAction(user.socio_id)
    }

    const filteredClientes = clientes.filter( cliente => {
        return cliente.nombre_comercial.toLowerCase().includes( search.toLowerCase())
    })

    return (
        <React.Fragment>
        { showFicha && <ClientesFicha clienteId={selectedCliente} toggleFicha={toggleFicha} /> }
        <nav className={`navbar${showListado} navbar-white bg-white justify-content-between`} style={{marginTop:"-7px"}}>
            <span style={{fontSize:"1.7rem"}}>CLIENTES</span>
            <form className="form-inline input-group-sm">
                <div className="btn btn-secondary btn-sm mr-4" onClick={() => { clienteAEditar(''); toggleFicha() }}>+ Nuevo Cliente</div>
                <input className="form-control mr-sm-2" onChange={e => setSearch(e.target.value)} type="search" placeholder="Texto a buscar..." aria-label="Search" />
            </form>
        </nav>
        <div id="listado" className={`content${showListado}`}>
            <div id="tabla" className="tabla">
                <div className="listado-cabecera">
                    <div className="tabla-cabecera">
                        <div className="clientes-nombre">Nombre</div>
                        <div className="clientes-contacto">Contacto</div>
                        <div className="clientes-telefono">Teléfono</div>
                        <div className="clientes-provincia">Provincia</div>
                        <div className="clientes-activo">Activo</div>
                        {/*<div className="clientes-borrar"></div>*/}
                    </div>
                </div>
                <div className="listado-cuerpo">
                    {   
                        filteredClientes ? 
                        filteredClientes.sort((a, b) => (a.nombre_comercial < b.nombre_comercial ? -1 : (a.nombre_comercial > b.nombre_comercial ? 1 : 0))).map((cliente) => 
                            <div className="tabla-cuerpo" key={cliente.id} onClick={() => { clienteAEditar(cliente.id); toggleFicha() }}>
                                <div className="clientes-nombre text-break">{cliente.nombre_comercial}</div>
                                <div className="clientes-contacto text-break">{cliente.contacto_persona}</div>
                                <div className="clientes-telefono text-break">{cliente.contacto_telefono}</div>
                                <div className="clientes-provincia text-break">{cliente.provincia}</div>
                                <div className="clientes-activo text-break">{cliente.activo}</div>
                                {/*
                                <div className="clientes-borrar text-break">
                                    <a href="/" onClick={(e) => showConfirmationModal(e, cliente.id)}>
                                        <i className="fas fa-trash-alt fa-2x text-danger"></i>
                                    </a>
                                </div>
                                */}
                            </div>
                        ) 
                        : <div className="text-center"></div>
                    }
                </div>
            </div>
        </div>
        <Modal handleOnDelete={handleOnDelete} />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    loading: state.loading,
    clientes: state.clientes,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchFetchAllClientesAction: (socio_id) => dispatch(fetchAllClientes(socio_id)),
    dispatchDeleteAction: (clienteId, onSuccess, onError) => dispatch(deleteClienteById(clienteId, onSuccess, onError))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientesListado);

const Modal = ({ handleOnDelete }) => (
    <div className="modal" id="confirmationModal" tabIndex="-1" role="dialog">
        <div role="document" className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Confirmation</h5>
                </div>
                <div className="modal-body">
                    <p>Are you sure, you want to delete this note ?</p>
                </div>
                <div className="modal-footer">
                    <button type="button" data-dismiss="modal" className="btn btn-secondary">
                        No
                    </button>
                    <button type="button" data-dismiss="modal" onClick={handleOnDelete} className="btn btn-primary">
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
);
