import React from 'react';
import { Link } from 'react-router-dom'

const PedidosNav = () => (

    <nav className="navbar navbar-expand-lg navbar-light bg-light text-dark">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item">
                    <Link className="nav-link" to="/pedidos">Inicio</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/pedidos">/&nbsp;&nbsp;&nbsp;JAMONES Y PALETAS</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/pedidos">/&nbsp;&nbsp;&nbsp;ENTEROS</Link>
                </li>
            </ul>
            <form className="form-inline my-2 my-lg-0">
                <input className="form-control mr-sm-2" type="search" placeholder="Buscar Producto" />
                <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Buscar</button>
            </form>
        </div>
    </nav>

);

export default PedidosNav;