import { RESET_USER_INFO } from '../user/userConstants'
import { 
    SET_ALL_PLATAFORMAS, 
    ADD_PLATAFORMA, 
    REMOVE_PLATAFORMA, 
    UPDATE_PLATAFORMA 
} from './plataformasConstants'

export default function plataformaReducer(state=[], action) {
    switch(action.type) {
        case SET_ALL_PLATAFORMAS:
            return action.payload;
        case ADD_PLATAFORMA:
            return state.concat(action.payload);
        case REMOVE_PLATAFORMA:
            return state.filter(item=>item.id !== action.payload);
        case UPDATE_PLATAFORMA:
            return state.map(item => {
                if(item.id === action.payload.plataformaId)
                    return {...item, ...action.payload.data};
                else
                    return item;
            });
        case RESET_USER_INFO:
            return [];
        default:
            return state;
    }
}