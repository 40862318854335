import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form'

import { createUsuario, getUsuarioById, updateUsuarioById, deleteUsuarioById } from '../../../../redux/usuarios/usuariosActions';

const UsuariosFicha = ({user, usuarios, socios, clientes, toggleFicha, usuarioId, dispatchCreateUsuarioAction, dispatchGetUsuarioByIdAction, dispatchUpdateUsuarioAction, dispatchFetchAllSociosAction, dispatchDeleteAction}) => {
  
  /* Valores iniciales según perfil usuario */

  const [clienteDisabled, setClienteDisabled] = useState(false);
  const [comercialDisabled, setComercialDisabled] = useState(true);
  const [socioDisabled, setSocioDisabled] = useState(true);
  const [perfiles, setPerfiles] = useState([])

  const defaultValues = {nombre: '', apellidos: '', email: '', socio_id: user.socio_id, cliente_id: '', password: '', activo: true, perfil: '', comercial_id: '' }

  /* Validación Formulario */
  const { register, handleSubmit, setValue, errors } = useForm({ defaultValues: defaultValues })
  const onSubmit = (data) => {
    const id = (usuarioId) ? usuarioId : ''
    data  = { ...{ id: id }, ...data }
    if (usuarioId) {
      dispatchUpdateUsuarioAction(usuarioId, data, () => {
          toast.success('Usuario actualizado con éxito!')
          toggleFicha()
      }, (message) => toast.error(`Error: ${message}`))
    } else {
      dispatchCreateUsuarioAction(data, () => {
          toast.success('Usuario creado con éxito!');
          toggleFicha();
      }, (message) => toast.error(`Error: ${message}`));
    }
  }

  const populatePerfiles = useCallback (
    () => {
      if (user.perfil === 1) {
        setPerfiles([ { perfil: "Ameta", valor: 1 }, { perfil: "Socio", valor: 2 } ])
        if (socioDisabled === false) setSocioDisabled(true)
        if (clienteDisabled === false) setClienteDisabled(true)
        console.log("se ejecuta")
      } else if (user.perfil === 2 ) {
        setPerfiles([ { perfil: "Socio", valor: 2 }, { perfil: "Comercial", valor: 3 }, { perfil: "Cliente", valor: 4 } ] )
        if (socioDisabled === false) setSocioDisabled(true)
      }
    },
    [clienteDisabled, socioDisabled, user.perfil],
  )

  useEffect(() => {
    if (usuarioId) {
      dispatchGetUsuarioByIdAction(usuarioId, ({ nombre, apellidos, email, socio_id, cliente_id, activo, perfil, comercial_id }) => {
          setValue("nombre", nombre)
          setValue("apellidos", apellidos)
          setValue("email", email)
          setValue("socio_id", socio_id)
          setValue("cliente_id", cliente_id)
          setValue("activo", activo)
          setValue("perfil", perfil)
          setValue("comercial_id", comercial_id)
          setClienteDisabled([2,3].includes(perfil) ? true : false)
          setComercialDisabled([2,4].includes(perfil) ? true : false)
      });
      if (perfiles.length === 0) populatePerfiles();
    }
  }, [dispatchGetUsuarioByIdAction, usuarios, usuarioId, dispatchFetchAllSociosAction, user.socio_id, populatePerfiles, perfiles, setValue ]);



  if (perfiles.length === 0) populatePerfiles()

  const handleOnDelete = () => {
    dispatchDeleteAction(usuarioId, () => {
        window.$('#confirmationModal').modal('hide');
        toast.success('usuario Borrado Correctamente!');
        toggleFicha()
    }, (message) => {
        window.$('#confirmationModal').modal('hide');
        toast.error(`Error: ${message}`);
    });
  };

  const showConfirmationModal = (event) => {
    event.preventDefault();
    window.$('#confirmationModal').modal('show');
  };

  const onChangePerfil = (e) => {
    console.log("OnChangePerfil, ", user.perfil, e.currentTarget.value)
    if (user.perfil === 1) {
      setValue("cliente_id", '0')
      if (['1'].includes(e.currentTarget.value)) {
        setValue("socio_id", '0')
        setClienteDisabled(true)
        setSocioDisabled(true)
      } else {
        setSocioDisabled(false)
      }
    } else {
      if (['2','3'].includes(e.currentTarget.value)) { 
        setValue("cliente_id", '0')
        setClienteDisabled(true)
      } else {
        setClienteDisabled(false)
      }
      if (['2','4'].includes(e.currentTarget.value)) { 
        setComercialDisabled(true)
        setValue("comercial_id", '')
      } else {
        setComercialDisabled(false)
      }
    }
  };

  return (
    <React.Fragment>
    <div className="container mt-4">
      <div className="row d-block">
        <div className="col-md-12">
          <h2>FICHA USUARIO</h2>
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
            <div className="form-group col-md-2">
                <label htmlFor="socio_id">Perfil *</label>
                <select 
                  ref={register( { required: { value: true, message: "Seleccione un perfil" } })}
                  type="text"
                  id="perfil"
                  name="perfil"
                  onChange={onChangePerfil}
                  className="form-control">
                    <option key={-1} value="" disabled>Seleccionar opción</option>
                    {perfiles.map((per) => <option key={per.valor} value={per.valor}>{per.perfil}</option>)}
                </select>
                {errors.perfil && <p className='text-danger mt-1'>{errors.perfil.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="email">Email *</label>
                <input
                  ref={ register({
                    required: {
                      value: true, 
                      message: "Campo requerido"
                    },
                    maxLength: {
                      value: 50,
                      message: "50 caracteres máximo"
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Dirección Email Inválida"
                    }
                  })}
                  type="text"
                  id="email"
                  placeholder="Email"
                  name="email"

                  className="form-control" />
                {errors.email && <p className='text-danger mt-1'>{errors.email.message}</p>}
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="nombre">Nombre *</label>
                <input
                  ref={register( { required: { value:true, message: "Campo requerido" },  maxLength: { value: 35, message: "Tamaño máximo 35 caracteres" }} )}
                  type="text"
                  id="nombre"
                  placeholder="Nombre"
                  name="nombre"
                  className="form-control" />
                {errors.nombre && <p className='text-danger mt-1'>{errors.nombre.message}</p>}

              </div>
              <div className="form-group col-md-3">
                <label htmlFor="apellidos">Apellidos *</label>
                <input
                  ref={register( { required: { value:true, message: "Campo requerido" },  maxLength: { value: 50, message: "Tamaño máximo 50 caracteres" }} )}
                  type="text"
                  id="apellidos"
                  placeholder="Apellidos"
                  name="apellidos"
                  className="form-control" />
                {errors.apellidos && <p className='text-danger mt-1'>{errors.apellidos.message}</p>}                 
              </div>
              <div className="form-group col-md-1">
                  <div className="form-check">
                      <div>Activado</div>
                      <label className="switch mt-2">
                          <input 
                            ref={register}
                            type="checkbox"
                            id="activo"
                            name="activo" />
                          <span className="slider round"></span>
                      </label>
                  </div>
              </div>    
            </div>
            <div className="form-row"> 
              <div className="form-group col-md-2">
                <label htmlFor="comercial_id">Cód. Comercial *</label>
                <input
                  ref={register( {  maxLength: { value: 20, message: "Tamaño máximo 20 caracteres" }} )}
                  disabled={comercialDisabled}
                  type="text"
                  id="comercial_id"
                  placeholder="Comercial"
                  name="comercial_id"
                  className="form-control" />
                {errors.comercial_id && <p className='text-danger mt-1'>{errors.comercial_id.message}</p>}                 
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="socio_id">Socio *</label>
                <select 
                  ref={register( { required: { value: true, message: "Seleccione un socio" } })}
                  disabled={socioDisabled}
                  type="text"
                  id="socio_id"
                  name="socio_id"
                  className="form-control">
                    <option key={-1} value="" disabled>Seleccionar opción</option>
                    <option key={0} value="0">CENTRAL AMETA</option>
                    {socios.map((soc) => <option key={soc.id} value={soc.id}>{soc.nombre_comercial}</option>)}
                </select>
                {errors.socio_id && <p className='text-danger mt-1'>{errors.socio_id.message}</p>} 
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="cliente_id">Cliente *</label>
                <select
                  ref={register( { required: { value: true, message: "Seleccione un cliente" } })}
                  disabled={clienteDisabled}
                  type="text"
                  id="cliente_id"
                  name="cliente_id"
                  className="form-control">
                    <option key={-1} value="" disabled>Seleccionar opción</option>
                    <option key={0} value="0">SIN CLIENTE</option>
                    {clientes.sort((a, b) => (a.nombre_comercial < b.nombre_comercial ? -1 : (a.nombre_comercial > b.nombre_comercial ? 1 : 0))).map((cli) => <option key={cli.id} value={cli.id}>{cli.nombre_comercial}</option>)}
                </select>
                {errors.cliente_id && <p className='text-danger mt-1'>{errors.cliente_id.message}</p>} 
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="password">Contraseña *</label>
                <input
                  ref={register( { required: { value: (usuarioId) ? false : true, message: "Es obligatoria la contraseña para usuarios nuevos" }, minLength: { value: 8, message: "Contraseña mínimo 8 caracteres" } })}
                  type="password"
                  id="password"
                  placeholder="Mín. 8 dígitos"
                  name="password"
                  className="form-control" />
                {errors.password && <p className='text-danger mt-1'>{errors.password.message}</p>} 
              </div>
            </div>
            <button type="submit" className="btn btn-success my-2">
              Guardar
            </button>
            <button className="btn btn-danger m-2" onClick={() => toggleFicha()}>Descartar</button>
            <button className="btn btn-outline-secondary m-2" onClick={(e) => showConfirmationModal(e, usuarioId)}>Borrar Usuario</button>
          </form>
        </div>
      </div>
      <div onClick={() => toggleFicha()}
        style={{
          position: "absolute",
          right: "30px",
          top: "80px",
          cursor: "pointer",
          fontSize: "1.5em",
        }}
      >
        &#10005;
      </div>
    </div>
    <Modal handleOnDelete={handleOnDelete} />
    </React.Fragment>
  );

}

const mapStateToProps = state => ({
  user: state.user,
  usuarios: state.usuario,
  socios: state.socios,
  clientes: state.clientes
});

const mapDispatchToProps = dispatch => ({
    dispatchCreateUsuarioAction: (data, onSuccess, onError) => dispatch(createUsuario(data, onSuccess, onError)),
    dispatchUpdateUsuarioAction: (usuarioId, data, onSuccess, onError) => dispatch(updateUsuarioById(usuarioId, data, onSuccess, onError)),
    dispatchGetUsuarioByIdAction: (usuarioId, onSuccess) => dispatch(getUsuarioById(usuarioId, onSuccess)),
    dispatchDeleteAction: (usuarioId, onSuccess, onError) => dispatch(deleteUsuarioById(usuarioId, onSuccess, onError))
});
export default connect(mapStateToProps, mapDispatchToProps)(UsuariosFicha);

const Modal = ({ handleOnDelete }) => (
  <div className="modal" id="confirmationModal" tabIndex="-1" role="dialog">
      <div role="document" className="modal-dialog">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title">Confirmation</h5>
              </div>
              <div className="modal-body">
                  <p>¿Desea borrar el Usuario?</p>
              </div>
              <div className="modal-footer">
                  <button type="button" data-dismiss="modal" className="btn btn-secondary">
                      No
                  </button>
                  <button type="button" data-dismiss="modal" onClick={handleOnDelete} className="btn btn-primary">
                      Si
                  </button>
              </div>
          </div>
      </div>
  </div>
);