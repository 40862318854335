import React from "react";
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom";

function UserFicha() {

  const { register, handleSubmit, errors } = useForm();

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const validateEmail = async (value) => {
    await sleep(1000);
    if (value === "a@a.es")
      return false;
    else
      return true;
  }

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="container contenedor">
      <div className="row d-block">
        <div className="col-md-12">
          <h2>Perfil de Usuario</h2>
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="nombre">Nombre *</label>
                <input name="nombre" type="text" className="form-control" id="nombre" placeholder="Nombre" ref={register({ required: true, minLength: 3 })} />
                {errors.nombre && errors.nombre.type === 'required' && (<p className="text-danger my-0">El campo Nombre es obligatorio</p>)}
                {errors.nombre && errors.nombre.type === 'minLength' && (<p className="text-danger my-0">El Nombre ha de tener un mínimo de 3 letras</p>)}
              </div>
              <div className="form-group col-md-8">
                <label htmlFor="apellidos">Apellidos *</label>
                <input name="apellidos" type="text" className="form-control" id="apellidos" placeholder="Apellidos" ref={register({ required: true, minLength: 3 })} />
                {errors.apellidos && errors.apellidos.type === 'required' && (<p className="text-danger my-0">El campo Apellidos es obligatorio</p>)}
                {errors.apellidos && errors.apellidos.type === 'minLength' && (<p className="text-danger my-0">Los Apellidos han de tener un mínimo de 3 letras</p>)}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-8">
                <label htmlFor="email">E-Mail *</label>
                <input name="email" type="text" className="form-control" id="email" placeholder="E-Mail" ref={register({ required: true, validate: validateEmail, pattern: /\S+@\S+\.\S+/ })} />
                {errors.email && errors.email.type === 'required' && (<p className="text-danger my-0">El campo E-Mail es obligatorio</p>)}
                {errors.email && errors.email.type === 'pattern' && (<p className="text-danger my-0">El E-mail introducido parece ser una dirección no válida</p>)}
                {errors.email && errors.email.type === 'validate' && (<p className="text-danger my-0">El E-mail ya está siendo utilizado por otro usuario</p>)}
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="pin">Cambiar PIN</label>
                <input name="pin" type="text" className="form-control" id="pin" placeholder="Nueva Contraseña" maxLength="4" ref={register({ minLength: 4, maxLength: 4, pattern: /^[0-9]+$/i })} />
                {errors.pin && (<p className="text-danger my-0">El PIN ha de ser una cifra de 4 número y que puede contener ceros por el principio.</p>)}
              </div>
            </div>
            <button type="submit" name="submit" className="btn btn-success my-2" ref={register} >
              Guardar
            </button>
            <Link className="btn btn-danger m-2" to="/">Descartar</Link>
          </form>
        </div>
      </div>
      <Link to="/"
        style={{
          position: "absolute",
          right: "30px",
          top: "80px",
          cursor: "pointer",
          fontSize: "1.5em",
          color: "black"
        }}
      >
        &#10005;
      </Link>
    </div>
  );
}

export default UserFicha;
