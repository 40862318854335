import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './categoriasListado.css'
import { fetchAllCategorias, deleteCategoriaById } from '../../../../redux/categorias/categoriasActions';

import CategoriasFicha from '../CategoriasFicha';

const CategoriasListado = ({ user, categorias, dispatchDeleteAction, dispatchFetchAllCategoriasAction }) => {

    const [selectedCategoria, setSelectedCategoria] = useState('');
    const [showFicha, setShowFicha] = useState(false);
    const [showListado, setShowListado] = useState('');
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatchFetchAllCategoriasAction(user.socio_id);
    }, [user, dispatchFetchAllCategoriasAction]);
/*
    const showConfirmationModal = (event, categoriaId) => {
        event.preventDefault();
        setSelectedCategoria(categoriaId);
        window.$('#confirmationModal').modal('show');
    };
*/
    const categoriaAEditar = (categoriaId) => {
        setSelectedCategoria(categoriaId);
    }

    const handleOnDelete = () => {
        dispatchDeleteAction(user.socio_id, selectedCategoria, () => {
            window.$('#confirmationModal').modal('hide');
            toast.success('Categoria Borrada Correctamente!');
        }, (message) => {
            window.$('#confirmationModal').modal('hide');
            toast.error(`Error: ${message}`);
        });
    };

    const toggleFicha = () => {
        setShowFicha( showFicha ? false : true )
        setShowListado( !showFicha ? ' d-none' : '')
        showFicha && dispatchFetchAllCategoriasAction(user.socio_id)
    }

    const filteredCategorias = categorias 
        ? categorias.filter( categoria => { return categoria.categoria.toLowerCase().includes( search.toLowerCase()) })
                        .sort( (a, b) => (a.parent_cat < b.parent_cat ? -1 : (a.parent_cat > b.parent_cat ? 1 : 0)) || (a.parent_cat - b.parent_cat || a.order_cat - b.order_cat) )
        : []

    var categoriasPadre = []
    categoriasPadre[0] = {nombre: "INICIO"}
    categorias && categorias.filter(categ => { return categ.parent_cat === '0' }).map(cat => categoriasPadre[cat.id] = {nombre: cat.categoria})

    if (categorias) console.log("catPad", categoriasPadre)

    return (
        <React.Fragment>
        { showFicha && <CategoriasFicha categoriaId={selectedCategoria} toggleFicha={toggleFicha} /> }
        <nav className={`navbar${showListado} navbar-white bg-white justify-content-between`} style={{marginTop:"-7px"}}>
            <span style={{fontSize:"1.7rem"}}>CATEGORÍAS</span>
            <form className="form-inline input-group-sm">
                <div className="btn btn-secondary btn-sm mr-4" onClick={() => { categoriaAEditar(''); toggleFicha() }}>+ Nuevo Categoria</div>
                <input className="form-control mr-sm-2" onChange={e => setSearch(e.target.value)} type="search" placeholder="Buscar Subcategoría..." aria-label="Search" />
            </form>
        </nav>
        <div id="listado" className={`content${showListado}`}>
            <div id="tabla" className="tabla">
                <div className="listado-cabecera">
                    <div className="tabla-cabecera">
                        <div className="categorias-categoria">Categoría</div>
                        <div className="categorias-subcategoria">Subcategoría</div>
                        <div className="categorias-orden">Orden</div>
                        <div className="categorias-activo">Activo</div>
                        {/*<div className="categorias-borrar"></div>*/}
                    </div>
                </div>
                <div className="listado-cuerpo">
                    {   
                        filteredCategorias ? 
                        filteredCategorias.map((categoria) => 
                            <div className="tabla-cuerpo" key={categoria.id} onClick={() => { categoriaAEditar(categoria.id); toggleFicha() }}>
                                <div className="categorias-categoria text-break">{categoriasPadre[categoria.parent_cat].nombre}</div>
                                <div className="categorias-subcategoria text-break">{categoria.categoria}</div>
                                <div className="categorias-orden text-break">{categoria.order_cat}</div>
                                <div className="categorias-activo text-break">{categoria.activo}</div>
                                {/*
                                <div className="categorias-borrar text-break">
                                    <a href="/" onClick={(e) => showConfirmationModal(e, categoria.id)}>
                                        <i className="fas fa-trash-alt fa-2x text-danger"></i>
                                    </a>
                                </div>
                                */}
                            </div>
                        ) 
                        : <div className="text-center"></div>
                    }
                </div>
            </div>
        </div>
        <Modal handleOnDelete={handleOnDelete} />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    loading: state.loading,
    categorias: state.categorias,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchFetchAllCategoriasAction: (socio_id) => dispatch(fetchAllCategorias(socio_id)),
    dispatchDeleteAction: (socio_id, categoriaId, onSuccess, onError) => dispatch(deleteCategoriaById(socio_id, categoriaId, onSuccess, onError))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriasListado);

const Modal = ({ handleOnDelete }) => (
    <div className="modal" id="confirmationModal" tabIndex="-1" role="dialog">
        <div role="document" className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Confirmation</h5>
                </div>
                <div className="modal-body">
                    <p>Are you sure, you want to delete this note ?</p>
                </div>
                <div className="modal-footer">
                    <button type="button" data-dismiss="modal" className="btn btn-secondary">
                        No
                    </button>
                    <button type="button" data-dismiss="modal" onClick={handleOnDelete} className="btn btn-primary">
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
);
