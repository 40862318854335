import { API } from '../constants'
import { 
    SET_ALL_PUNTOS, 
    ADD_PUNTO, 
    REMOVE_PUNTO, 
    UPDATE_PUNTO 
} from './puntosConstants'

export const fetchAllPuntos = (socio_id) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/puntos/lista/${socio_id}`,
        success: (response) => (setAllPuntos(response))
    }
});

export const createPunto = (data, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'POST',
        url: '/puntos',
        data,
        success: (punto) => (addPunto(punto)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getPuntoById = (socioId, puntoId, onSuccess) => ({
    type: API,
    payload: {
        method: 'GET',
        url: `/puntos/punto/${socioId}/${puntoId}`,
        postProcessSuccess: onSuccess
    }
});

export const updatePuntoById = (socioId, puntoId, data, onSucess, onError) => ({
    type: API,
    payload: {
        method: 'PATCH',
        url: `/puntos/`,
        data,
        success: (socioId, puntoId, data) => (updatePunto(socioId, puntoId, data)),
        postProcessSuccess: onSucess,
        postProcessError: onError
    }
});

export const deletePuntoById = (socioId, puntoId, onSuccess, onError) => ({
    type: API,
    payload: {
        method: 'DELETE',
        url: `/puntos/${socioId}/${puntoId}`,
        success: () => (removePunto(socioId, puntoId)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

const addPunto = (punto) => ({
    type: ADD_PUNTO,
    payload: punto
})

const setAllPuntos = (data) => ({
    type: SET_ALL_PUNTOS,
    payload: data
});

const updatePunto = (socioId, puntoId, data) => ({
    type: UPDATE_PUNTO,
    payload: { socioId, puntoId, data }
});

const removePunto = (socioId, puntoId) => ({
    type: REMOVE_PUNTO,
    payload: { socioId, puntoId },
});