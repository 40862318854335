import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './puntosListado.css'
import { fetchAllPuntos, deletePuntoById } from '../../../../redux/puntos/puntosActions';
import { fetchAllUsuarios } from '../../../../redux/usuarios/usuariosActions';
import { fetchAllClientes } from '../../../../redux/clientes/clientesActions'
import { fetchAllPlataformas } from '../../../../redux/plataformas/plataformasActions'

import PuntosFicha from '../PuntosFicha';

const PuntosListado = ({ user, puntos, dispatchDeleteAction, dispatchFetchAllPuntosAction, dispatchFetchAllUsuariosAction, dispatchFetchAllClientesAction, dispatchFetchAllPlataformasAction }) => {

    const [selectedPunto, setSelectedPunto] = useState('');
    const [showFicha, setShowFicha] = useState(false);
    const [showListado, setShowListado] = useState('');
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatchFetchAllPuntosAction(user.socio_id);
        dispatchFetchAllUsuariosAction(user.socio_id, user.cliente_id)
        dispatchFetchAllClientesAction(user.socio_id)
        dispatchFetchAllPlataformasAction(user.socio_id)
    }, [user, dispatchFetchAllPuntosAction, dispatchFetchAllUsuariosAction, dispatchFetchAllClientesAction, dispatchFetchAllPlataformasAction]);
/*
    const showConfirmationModal = (event, puntoId) => {
        event.preventDefault();
        setSelectedPunto(puntoId);
        window.$('#confirmationModal').modal('show');
    };
*/
    const puntoAEditar = (puntoId) => {
        setSelectedPunto(puntoId);
    }

    const handleOnDelete = () => {
        dispatchDeleteAction(selectedPunto, () => {
            window.$('#confirmationModal').modal('hide');
            toast.success('Punto Borrado Correctamente!');
        }, (message) => {
            window.$('#confirmationModal').modal('hide');
            toast.error(`Error: ${message}`);
        });
    };

    const toggleFicha = () => {
        setShowFicha( showFicha ? false : true )
        setShowListado( !showFicha ? ' d-none' : '')
        showFicha && dispatchFetchAllPuntosAction(user.socio_id)
    }

    const filteredPuntos = puntos.filter( punto => {
        return punto.nombre.toLowerCase().includes( search.toLowerCase())
    })

    return (
        <React.Fragment>
        { showFicha && <PuntosFicha puntoId={selectedPunto} toggleFicha={toggleFicha} /> }
        <nav className={`navbar${showListado} navbar-white bg-white justify-content-between`} style={{marginTop:"-7px"}}>
            <span style={{fontSize:"1.7rem"}}>PUNTOS DE VENTA</span>
            <form className="form-inline input-group-sm">
                <div className="btn btn-secondary btn-sm mr-4" onClick={() => { puntoAEditar(''); toggleFicha() }}>+ Nuevo Punto</div>
                <input className="form-control mr-sm-2" onChange={e => setSearch(e.target.value)} type="search" placeholder="Texto a buscar..." aria-label="Search" />
            </form>
        </nav>
        <div id="listado" className={`content${showListado}`}>
            <div id="tabla" className="tabla">
                <div className="listado-cabecera">
                    <div className="tabla-cabecera">
                        <div className="puntos-nombre">Nombre</div>
                        <div className="puntos-contacto">Contacto</div>
                        <div className="puntos-telefono">Teléfono</div>
                        <div className="puntos-provincia">Provincia</div>
                        <div className="puntos-activo">Activo</div>
                        {/*<div className="puntos-borrar"></div>*/}
                    </div>
                </div>
                <div className="listado-cuerpo">
                    {   
                        filteredPuntos.length > 0 ? 
                        filteredPuntos.map((punto) => 
                            <div className="tabla-cuerpo" key={punto.id} onClick={() => { puntoAEditar(punto.codigo_punto_venta); toggleFicha() }}>
                                <div className="puntos-nombre text-break">{punto.nombre}</div>
                                <div className="puntos-contacto text-break">{punto.contacto_persona}</div>
                                <div className="puntos-telefono text-break">{punto.contacto_telefono}</div>
                                <div className="puntos-provincia text-break">{punto.provincia}</div>
                                <div className="puntos-activo text-break">{punto.activo}</div>
                                {/*
                                <div className="puntos-borrar text-break">
                                    <a href="/" onClick={(e) => showConfirmationModal(e, punto.id)}>
                                        <i className="fas fa-trash-alt fa-2x text-danger"></i>
                                    </a>
                                </div>
                                */}
                            </div>
                        ) 
                        : <div className="text-center"></div>
                    }
                </div>
            </div>
        </div>
        <Modal handleOnDelete={handleOnDelete} />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    loading: state.loading,
    puntos: state.puntos,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    dispatchFetchAllPuntosAction: (socio_id) => dispatch(fetchAllPuntos(socio_id)),
    dispatchDeleteAction: (puntoId, onSuccess, onError) => dispatch(deletePuntoById(puntoId, onSuccess, onError)),
    dispatchFetchAllUsuariosAction: (socio_id, cliente_id) => dispatch(fetchAllUsuarios(socio_id, cliente_id)),
    dispatchFetchAllClientesAction: (socio_id) => dispatch(fetchAllClientes(socio_id)),
    dispatchFetchAllPlataformasAction: (socio_id) => dispatch(fetchAllPlataformas(socio_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(PuntosListado);

const Modal = ({ handleOnDelete }) => (
    <div className="modal" id="confirmationModal" tabIndex="-1" role="dialog">
        <div role="document" className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Confirmation</h5>
                </div>
                <div className="modal-body">
                    <p>Are you sure, you want to delete this note ?</p>
                </div>
                <div className="modal-footer">
                    <button type="button" data-dismiss="modal" className="btn btn-secondary">
                        No
                    </button>
                    <button type="button" data-dismiss="modal" onClick={handleOnDelete} className="btn btn-primary">
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
);
